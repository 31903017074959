import React, { useState, useEffect, useRef } from 'react';
import './NotesNav.scss';

import { helpers } from '../../../core/helpers';
import { Tree } from '../../../core/components';

// MUI
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Collapse from '@mui/material/Collapse';

const NotesNav = ({
  lang,
  history,
  notebooks,
  selectedNotebook,
  onNotebookSelected,
  onNewNoteClick,
  onAllNotesClick,
  breakPoint,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isCompacted, setIsCompacted] = useState(breakPoint !== 'large');

  useEffect(() => {
    setIsCompacted(breakPoint !== 'large');
  }, [breakPoint]);

  const handleNotebookListCollapse = () => {
    setIsOpen(!isOpen);
  };
  const handleGoToNotebooksClick = () => {
    history.push('/notebooks');
  };
  const handleAllNotesClick = () => {
    onAllNotesClick();
  };

  const handleListItemClick = (item) => {
    const id = item.id;
    onNotebookSelected(id);

    if (breakPoint === 'xSmall' && isCompacted === false) {
      setIsCompacted(!isCompacted);
    }
  };
  const toggleNavClick = () => {
    setIsCompacted(!isCompacted);
  };

  return (
    <>
      {selectedNotebook ? (
        <aside className={isCompacted ? 'cx-notes-nav cx-notes-nav-compacted' : 'cx-notes-nav'}>
          {isCompacted ? (
            <div className="cx-notes-nav-container">
              <div className="cx-notes-nav-toggle">
                <button type="button" onClick={toggleNavClick}>
                  {isCompacted ? <i className="icon-chevron-right" /> : <i className="icon-chevron-left" />}
                </button>
              </div>
              <div className="cx-notes-nav-compact-container">
                <button className="cx-button cx-button-new" onClick={onNewNoteClick}>
                  <i className="icon-plus" />
                </button>
                <button className="cx-button cx-button-simple" onClick={handleAllNotesClick}>
                  <i className="icon-note" />
                </button>
                <button className="cx-button cx-button-simple" onClick={handleGoToNotebooksClick}>
                  <i className="icon-repo" />
                </button>
                {isCompacted}
              </div>
            </div>
          ) : (
            <div className="cx-notes-nav-expanded-container">
              <div className="cx-notes-nav-container">
                <div className="cx-notes-nav-toggle">
                  <button type="button" onClick={toggleNavClick}>
                    {isCompacted ? <i className="icon-chevron-right" /> : <i className="icon-chevron-left" />}
                  </button>
                </div>
                <div className="cx-notes-nav-top-actions">
                  <button className="cx-button cx-button-new" onClick={onNewNoteClick}>
                    <i className="icon-plus" />
                    <span> {lang.newNote}</span>
                  </button>
                </div>
              </div>
              <div className="cx-notes-nav-notebooks">
                <List component="nav">
                  <ListItemButton onClick={handleAllNotesClick}>
                    <ListItemIcon>
                      <i className="icon-note" />
                    </ListItemIcon>
                    <ListItemText primary={lang.allNotes} />
                  </ListItemButton>
                  <ListItemButton onClick={handleGoToNotebooksClick}>
                    <ListItemIcon>
                      <i className="icon-repo" />
                    </ListItemIcon>
                    <ListItemText primary={lang.notebooks} />

                    <ListItemSecondaryAction onClick={handleNotebookListCollapse}>
                      {isOpen ? <i className="icon-chevron-down" /> : <i className="icon-chevron-up" />}
                    </ListItemSecondaryAction>
                  </ListItemButton>
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Tree
                      id="notebooks-tree"
                      data={helpers.buildTreeFromArray(notebooks)}
                      selectedItem={selectedNotebook}
                      onItemClick={handleListItemClick}
                      preIcon={<i className="icon-repo" />}
                      lang={lang}
                    />
                  </Collapse>
                </List>
              </div>
            </div>
          )}
        </aside>
      ) : null}
    </>
  );
};

export default NotesNav;
