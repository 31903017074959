import React from 'react';
import './ListingListItem.scss';

import { Money, NoImagePlaceholder } from '../../../../core/components';
import NumberFormat from 'react-number-format';

const ListingListItem = ({ lang, item, onSelected, onEdit, onDelete }) => {
  const showLotSize = (listingTypeId) => {
    return ['88fcab78-d392-4304-95b4-c0f2629e5893', '88fcab78-d392-4304-95b4-c0f2629e5894'].includes(listingTypeId);
  };

  return (
    <>
      <div onClick={(e) => onSelected(item)} key={item.id} className="cx-listings-list-item box1">
        <div className="cx-listings-list-item-image">
          {item.image.image ? (
            <img src={`${item.image.image}&s=0x200`} alt="Listing" />
          ) : (
            <NoImagePlaceholder multi={false} fontSize={100} />
          )}
          <Money number={item.askingPrice} symbol="$" />
        </div>

        <div className="cx-listings-list-item-content">
          <div className="cx-listings-list-item-content-address">{item.fullAddress}</div>

          <ul>
            <li>
              <i className="icon-icon-sqft" />
              {showLotSize(item.listingTypeId) ? (
                <>
                  <NumberFormat value={item.lotSize} displayType={'text'} thousandSeparator={true} />
                  <span>{item.lotSizeUnit}</span>
                </>
              ) : (
                <>
                  <NumberFormat value={item.buildingSize} displayType={'text'} thousandSeparator={true} />
                  <span>{item.buildingSizeUnit}</span>
                </>
              )}
            </li>
            <li>
              <i className="icon-icon-parking" />
              <span>{item.numberOfParkingSpaces}</span>
              <span>{lang.spaces}</span>
            </li>
            <li>
              <i className="icon-icon-floors" />
              <span>{item.numberOfUnits}</span>
              <span>{lang.units}</span>
            </li>
            <li>
              <i className="icon-icon-elevator" />
              <span>1</span>
              <span>{lang.elev}</span>
            </li>
          </ul>

          <div className="cx-admin-footer-actions">
            <button className="cx-button cx-button-edit" onClick={() => onEdit(item)}>
              <i className="icon-pencil" /> {lang.edit}
            </button>
            <button className="cx-button cx-button-delete" onClick={() => onDelete(item)}>
              <i className="icon-trash-2" /> {lang.delete}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingListItem;
