export const helpers = {
  // Sum an array
  sum: (arr) => arr.reduce((acc, curr) => acc + curr, 0),

  // create an array of numbers between min and max (edges included)
  range: (min, max) => Array.from({ length: max - min + 1 }, (_, i) => min + i),

  // pick a random number between min and max (edges included)
  random: (min, max) => min + Math.floor(max * Math.random()),

  // Given an array of numbers and a max...
  // Pick a random sum (< max) from the set of all available sums in arr
  randomSumIn: (arr, max) => {
    const sets = [[]];
    const sums = [];
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0, len = sets.length; j < len; j++) {
        const candidateSet = sets[j].concat(arr[i]);
        const candidateSum = helpers.sum(candidateSet);
        if (candidateSum <= max) {
          sets.push(candidateSet);
          sums.push(candidateSum);
        }
      }
    }
    return sums[helpers.random(0, sums.length)];
  },

  // Get a list of years
  generateArrayOfYears: () => {
    var max = new Date().getFullYear();
    var min = max - 30;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  },

  // Format number to currency
  getCurrencyValue: (value, currency) => {
    let curCode = 'USD';
    let culture = 'en-US';
    if (currency && currency.startsWith('EU')) {
      curCode = 'EUR';
      culture = 'de-DE';
    }
    return new Intl.NumberFormat(culture, {
      style: 'currency',
      currency: curCode,
      minimumFractionDigits: 0,
    }).format(value);
  },

  hasChanged: (originalValue, newValue) => {
    if (JSON.stringify(originalValue) === JSON.stringify(newValue)) {
      return false;
    }
    return true;
  },

  // Recursion
  buildTreeFromArray: (arr) => {
    const map = {};
    const roots = [];

    // Initialize the map and set up the children array for each node
    arr.forEach((item) => {
      item.children = [];
      map[item.id] = item;
    });

    // Iterate through the array to build the tree
    arr.forEach((item) => {
      if (item.parentId !== null) {
        // If the node has a parent, add it to the parent's children array
        map[item.parentId].children.push(item);
      } else {
        // Otherwise, it's a root node
        roots.push(item);
      }
    });

    return roots;
  },

  // Sorting
  sortTextAsc: (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  },

  sortTextDesc: (a, b) => {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
  },
  sortDateTimeAsc: (a, b) => {
    return new Date(b) - new Date(a);
  },
  sortDateTimeDesc: (a, b) => {
    return new Date(a) - new Date(b);
  },
};
