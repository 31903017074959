export class WineSearchDto {
  text = null;
  sort = 'createDate';
  sortDirection = 'desc';
}

export class WineSearchRequest {
  limit = 30;
  skip = 0;
  count = 0;
  search = new WineSearchDto();
}
