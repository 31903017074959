import React from 'react';
import './Panel.scss';
import PropTypes from 'prop-types';

const Panel = ({ title, className, children }) => {
  return (
    <div className={className == null ? 'cx-panel' : `cx-panel ${className}`}>
      {title ? (
        <h2>
          <span>{title}</span>
          <p>&nbsp;</p>
        </h2>
      ) : null}
      <div className="content" style={{ display: 'block' }}>
        {children}
      </div>
    </div>
  );
};

Panel.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};
Panel.defaultProps = {};
export default Panel;
