import React, { useState } from 'react';
import './ListingEditAmenities.scss';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export const ListingEditAmenities = ({ amenities, onChange }) => {
  const [items, setItems] = useState(amenities);
  const handleAmenitySwitchChange = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;

    // Update array property with new values.
    const updatedItems = items.map((x) => (x.name === name ? { ...x, isChecked: checked } : x));
    setItems(updatedItems);
    onChange(updatedItems);
  };

  const checkboxes = items.map((x, index) => (
    <FormControlLabel
      key={x.name}
      control={
        <Switch
          name={x.name}
          checked={x.isChecked}
          onChange={handleAmenitySwitchChange}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      }
      label={x.label}
    />
  ));

  return (
    <>
      <div className="cx-admin-listing-edit-amenities">
        <div className="amenities-container">{checkboxes}</div>
      </div>
    </>
  );
};

export default ListingEditAmenities;
