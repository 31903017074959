import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import App from './App';

import configureStore from '../core/redux/configureStore';
const store = configureStore();

// Override to fix these issues:
// https://github.com/mui/material-ui/issues/14427#issuecomment-2147556622
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // Puts the label into notched position
          '.MuiInputLabel-outlined:has(+ .MuiOutlinedInput-root > .MuiOutlinedInput-input:-webkit-autofill)': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
          // Creates a hole around the text inside the border
          '.MuiOutlinedInput-input:-webkit-autofill + .MuiOutlinedInput-notchedOutline > legend': {
            maxWidth: '100%',
          },
        },
      },
    },
  },
});

const AppContainer = () => {
  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Router>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </StyledEngineProvider>
        </Router>
      </SnackbarProvider>
    </Provider>
  );
};

export default AppContainer;
