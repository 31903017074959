import React, { useEffect } from 'react';
import './NotebookEditForm.scss';

import useApi from '../../../../core/useApi';
import { CxSelect } from '../../../../core/components';

// Mui
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const NotebookEditForm = ({ lang, notebook, notebooks, formEventHandler, contentGroupId }) => {
  const { post, put } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const parentIdValue = !notebook.parentId ? '' : notebook.parentId;

    reset({
      id: notebook.id,
      name: notebook.name,
      parentId: parentIdValue,
      sharedWithMe: notebook.sharedWithMe,
      contentGroupId: contentGroupId,
    });

    setParentId(parentIdValue);
  }, [notebook]);

  const onSaveClick = async (data) => {
    if (data.id === '') {
      await post('notebook', data);
    } else {
      await put(`notebook/${data.id}`, data);
    }

    reset();
    enqueueSnackbar('Notebook saved!', {
      variant: 'success',
    });

    formEventHandler('saved');
  };

  const onCancelClick = () => {
    reset();
    formEventHandler('canceled');
  };

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  // Group Select
  const [parentId, setParentId] = React.useState(notebook.parentId == null ? '' : notebook.parentId);

  const notebookList = () => {
    const nb = notebooks.filter((x) => x.id !== notebook.id).map(({ id, name }) => ({ name: name, value: id }));
    nb.unshift({ value: '', name: '- No Parent -' });
    return nb;
  };

  const handleGroupWithChange = (value) => {
    setParentId(value);
    setValue('parentId', value, true);
  };

  return (
    <div className="cx-notebooks-form">
      <section>
        <form onSubmit={handleSubmit(onSaveClick)}>
          <input type="hidden" name="id" {...register('id')} />
          <input type="hidden" name="contentGroupId" {...register('contentGroupId')} />
          <input type="hidden" name="sharedWithMe" {...register('sharedWithMe')} />
          <div>
            <TextField
              name="name"
              label={lang.name}
              {...register('name', {
                required: { value: true, message: lang.validationFieldRequired },
                maxLength: { value: 64, message: 'No more than 64 chars please.' },
              })}
              type="text"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              autoComplete="off"
              fullWidth
            />
            {errors.name && <span className="cx-form-validation-message">{errors?.name?.message}</span>}
          </div>

          {notebookList().length > 0 && !notebook.sharedWithMe ? (
            <div>
              <CxSelect
                name="parentId"
                label={lang.groupWith}
                fullWidth
                items={notebookList()}
                defaultValue={parentId}
                onChange={handleGroupWithChange}
              />
            </div>
          ) : null}

          <div className="cx-admin-footer-actions">
            <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
              {lang.cancel}
            </button>
            <button type="submit" className="cx-button cx-button-save">
              {lang.save}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default NotebookEditForm;
