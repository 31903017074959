import React, { useEffect, useState, useRef } from 'react';
import './WineContainer.scss';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import responsive from '../../../core/responsive';
import { Breadcrumb, Loader, EmptyState, BrowserWindowsReachBottomScroll } from '../../../core/components';
import { WineSearchRequest } from './WineSearch.dto';
import WineService from './wine.service';
import WineItem from './WineItem';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

const WineContainer = ({ history }) => {
  const lang = useSelector((state) => state.app.lang);
  const { enqueueSnackbar } = useSnackbar();
  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.wines, path: null },
  ];

  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [items, setItems] = useState([]);
  const [searchRequest, setSearchRequest] = useState(new WineSearchRequest());
  const [isSearching, setIsSearching] = useState(false);
  const searchRef = useRef();

  // Responsive ====================================================================
  const [isLarge, setIsLarge] = useState(window.innerWidth >= responsive.mdMinWidth);
  // timeOutFunctionId stores a numeric ID which is used by clearTimeOut to reset timer.
  var timeOutFunctionId;
  window.onresize = function (event) {
    // clearTimeOut() resets the setTimeOut() timer due to this the function in setTimeout() is fired after we are done resizing.
    clearTimeout(timeOutFunctionId);

    // setTimeout returns the numeric ID which is used by clearTimeOut to reset the timer.
    timeOutFunctionId = setTimeout(() => {
      const width = event.srcElement.innerWidth;

      if (width < responsive.mdMinWidth) {
        if (isLarge) {
          setIsLarge(false);
        }
      }

      if (width > responsive.mdMinWidth) {
        if (!isLarge) {
          setIsLarge(true);
        }
      }
    }, 500);
  };

  // Databind
  useEffect(() => {
    databind(searchRequest);
  }, []);

  const databind = (search, loadMore) => {
    const service = new WineService();
    service
      .search(search)
      .then((x) => {
        if (loadMore) {
          var newItems = [...items];
          setItems(newItems.concat(x.data.items));
        } else {
          setItems(x.data.items);
        }

        search.count = x.data.count;
        search.limit = x.data.limit;
        search.skip = x.data.skip;
        setSearchRequest(search);
        setLoadingCompleted(true);
      })
      .catch((er) => service.handleHttpError(er, enqueueSnackbar));
  };

  const searchClick = () => {
    const request = { ...searchRequest };
    request.search.text = searchRef.current.value;
    request.skip = 0;
    databind(request, false);
    setIsSearching(true);
  };
  const clearSearchClick = () => {
    searchRef.current.value = null;
    const request = { ...searchRequest };
    request.search.text = null;
    request.skip = 0;
    databind(request, false);
    setIsSearching(false);
  };

  const handleLoadMore = () => {
    const request = { ...searchRequest };
    request.skip = parseInt(request.skip) + parseInt(request.limit);
    databind(request, true);
  };

  const newWineClick = () => {
    history.push(`/wines/new`);
  };
  const handleWineItemClick = (item) => {
    history.push(`/wines/${item.id}`);
  };
  const handleWineItemLinkClick = (item) => {
    searchRef.current.value = item;
  };

  // Filter pop over
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleSortClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onSortClick = (sortField) => {
    const request = { ...searchRequest };
    const { sort } = request.search;

    if (sortField === sort) {
      request.search.sortDirection = request.search.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      request.search.sort = sortField;
      request.search.sortDirection = 'asc';
    }

    request.skip = 0;
    setSearchRequest(request);
    databind(request);
    setAnchorEl(null);
  };

  return (
    <div className="cx-wines">
      <Breadcrumb items={breadcrumb} />
      <div className="cx-wines-search">
        <Paper component="form" sx={{ p: '0px 8px', display: 'flex', alignItems: 'center' }}>
          <InputBase
            type="text"
            inputRef={searchRef}
            placeholder={lang.searchWine}
            sx={{ ml: 1, flex: 1 }}
            inputProps={{ 'aria-label': lang.searchWine }}
          />
          {isSearching ? (
            <IconButton type="submit" aria-label="clear-search" onClick={clearSearchClick} size="large">
              <i className="icon-times" />
            </IconButton>
          ) : (
            <IconButton type="submit" aria-label="search" onClick={searchClick} size="large">
              <i className="icon-search" />
            </IconButton>
          )}
          <Divider orientation="vertical" />
          <IconButton type="button" aria-label="sort" onClick={handleSortClick} size="large">
            <i className="icon-sort-amount-asc" />
          </IconButton>
          <Divider orientation="vertical" />
          <button type="button" className="cx-button cx-button-new" onClick={newWineClick}>
            {isLarge ? lang.newWine : <i className="icon-plus" />}
          </button>
        </Paper>
      </div>

      {!loadingCompleted ? (
        <Loader />
      ) : (
        <>
          {!isSearching && loadingCompleted && items.length === 0 ? (
            <EmptyState
              title={lang.welcomeToTheWineCellarTitle}
              description={lang.welcomeToTheWineCellarDesc}
              onActionClick={newWineClick}
              actionText={lang.newWine}
            />
          ) : (
            <>
              {items.map((item, index) => (
                <WineItem
                  key={index}
                  lang={lang}
                  item={item}
                  onWineItemClick={handleWineItemClick}
                  onWineItemLinkClick={handleWineItemLinkClick}
                />
              ))}

              {items.length < searchRequest.count ? (
                // <button
                //   id="load-more-beers"
                //   type="button"
                //   className="cx-button cx-button-action"
                //   onClick={handleLoadMore}
                // >
                //   {lang.loadMore} <i className="icon-chevron-down" />
                // </button>
                <BrowserWindowsReachBottomScroll padding={3} action={handleLoadMore} />
              ) : null}
            </>
          )}

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className="cx-wine-search-sort">
              <h3>{lang.sortBy}</h3>
              <ul>
                <li onClick={() => onSortClick('rating')}>
                  {lang.rating}
                  {searchRequest.search.sort === 'rating' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
                <li onClick={() => onSortClick('abv')}>
                  {lang.abvPercent}
                  {searchRequest.search.sort === 'abv' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
                <li onClick={() => onSortClick('grape')}>
                  {lang.grape}
                  {searchRequest.search.sort === 'grape' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
                <li onClick={() => onSortClick('style')}>
                  {lang.style}
                  {searchRequest.search.sort === 'style' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
                <li onClick={() => onSortClick('name')}>
                  {lang.name}
                  {searchRequest.search.sort === 'name' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
                <li onClick={() => onSortClick('createDate')}>
                  {lang.added}
                  {searchRequest.search.sort === 'createDate' ? (
                    searchRequest.search.sortDirection === 'asc' ? (
                      <i className="icon-sort-amount-asc" />
                    ) : (
                      <i className="icon-sort-amount-desc" />
                    )
                  ) : null}
                </li>
              </ul>
            </div>
          </Popover>
        </>
      )}
    </div>
  );
};

export default WineContainer;
