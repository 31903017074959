import React, { useCallback, useState, useEffect } from 'react';
import './ListingSearch.scss';

import { withRouter } from 'react-router-dom';
import ListingListItem from '../list/ListingListItem';
import ListingAdminService from '../ListingAdmin.service';

const ListingSearch = ({ lang, history }) => {
  const [items, setItems] = useState([]);

  const databind = useCallback(() => {
    const service = new ListingAdminService();
    service
      .search()
      .then((x) => {
        setItems(x.data.items);
      })
      .catch(service.handleHttpError);
  }, []);

  useEffect(() => {
    databind();
  }, [databind]);

  const onItemSelected = (item) => {
    history.push(`/listings/${item.id}`);
  };
  const onItemDelete = (item) => {
    history.push(`/listings/${item.id}`);
  };

  const listings = items.map((item) => (
    <ListingListItem
      key={item.id}
      lang={lang}
      item={item}
      onSelected={onItemSelected}
      onEdit={onItemSelected}
      onDelete={onItemDelete}
      isAdmin={true}
    />
  ));

  return (
    <>
      <div className="cx-listing-search">
        <div className="cx-listing-search-search">SOME SEARCH HERE</div>
        <div className="cx-listing-search-list">
          {listings}
          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ListingSearch);
