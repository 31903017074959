import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './Modal.scss';
import PropTypes from 'prop-types';

const Modal = React.memo(
  forwardRef(({ title, className, children, footer, lang, onModalClosed }, ref) => {
    const [showModal, setShowModal] = useState(false);
    let modalContainer = React.createRef();

    useImperativeHandle(ref, (display) => ({
      toggleModal(display) {
        setShowModal(display);
      },
    }));

    const toggleModal = (display, e) => {
      e.stopPropagation();
      e.preventDefault();
      setShowModal(display);
      if (onModalClosed) onModalClosed();
    };

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = (event) => {
      if (
        modalContainer &&
        modalContainer.current != null &&
        modalContainer.current.id != null &&
        event.target.id === modalContainer.current.id
      ) {
        setShowModal(false);
        if (onModalClosed) onModalClosed();
      }
    };

    return (
      <div
        ref={modalContainer}
        id="myModal"
        className={className == null ? 'modal' : `modal ${className}`}
        style={{ display: showModal ? 'block' : 'none' }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <span className="close" onClick={(e) => toggleModal(false, e)}>
              {lang ? lang.close : ''} &times;
            </span>
            {title ? <h2>{title}</h2> : <h2>&nbsp;</h2>}
          </div>
          <div className="modal-body">{children}</div>
          {footer ? (
            <div className="modal-footer">
              <h3>{footer}</h3>
            </div>
          ) : null}
        </div>
      </div>
    );
  })
);

Modal.propTypes = {
  lang: PropTypes.any,
  title: PropTypes.string,
  onModalClosed: PropTypes.func,
};

Modal.defaultProps = {};

export default Modal;
