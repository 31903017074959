import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';

const AppInactivityLogout = ({ lang, user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [lastActivity, setLastActivity] = useState(Date.now());
  const history = useHistory();
  const inactivityLimit = 300000; // 5 minute of inactivity

  useEffect(() => {
    const handleActivity = () => setLastActivity(Date.now());

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);

    const checkInactivity = () => {
      if (Date.now() - lastActivity > inactivityLimit) {
        if (user) {
          // Perform logout operation here
          enqueueSnackbar(lang.youHaveBeenLoggedOutDueToInactivity, {
            variant: 'warning',
            preventDuplicate: true,
          });
          history.push('/logout');
        }
      }
    };

    const interval = setInterval(checkInactivity, 1000); // Check every second

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      clearInterval(interval);
    };
  }, [lastActivity, history, user]);

  return null; // This component does not render anything
};

const mapStateToProps = (state, props = {}) => {
  return {
    lang: state.app.lang,
    user: state.auth.user,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppInactivityLogout);
