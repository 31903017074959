import React, { useState, useEffect } from 'react';
import './poi.scss';
import { useSnackbar } from 'notistack';
import { Loader } from '../../../../core/components';
import PointsOfInterestEdit from './poiEdit';
import PointOfInterestItem from './poiItem';

import useApi from '../../../../core/useApi';

const PointsOfInterest = ({ lang, listingId, emptyGuid }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const { get, post, put, del } = useApi();

  const getPoi = async (id) => {
    setIsLoading(true);
    try {
      const response = await get(`admin/listings/${id}/poi`);
      setItems(response);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPoi(listingId);
  }, []);

  const onAddClick = () => {
    setItem(null);
    setShowEdit(true);
  };
  const handleFormUpdated = async (eventType, formData) => {
    if (eventType === 'cancel') {
      setShowEdit(false);
    }

    try {
      setIsLoading(true);

      if (eventType === 'created') {
        const response = await post(`admin/listings/${listingId}/poi`, formData);

        const newItems = [...items];
        newItems.push(response);
        setItems(newItems);
        setShowEdit(false);
        setItem(null);
        enqueueSnackbar('Points of interest created!', { variant: 'success' });
      }

      if (eventType === 'updated') {
        await put(`admin/listings/${listingId}/poi/${formData.id}`, formData);
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);
      console.log(error.message.cause.message);
      console.log(error.message.cause.detail);
      //setErrors(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const onItemSelected = (item) => {
    setItem(item);
    setShowEdit(true);
  };
  const onItemDeleted = async (item) => {
    const response = await del(`admin/listings/${listingId}/poi`);
    // const service = new PointsOfInterestService();
    // service
    //   .delete(item.id, item)
    //   .then((x) => {
    const newItems = items.filter((x) => x.id !== item.id);
    setItems(newItems);
    //   })
    //   .catch(service.handleHttpError);
  };

  const poiList = items.map((x) => (
    <div className="box1" key={x.id}>
      <PointOfInterestItem
        lang={lang}
        key={x.id}
        item={x}
        isAdmin={true}
        onSelected={onItemSelected}
        onDeleted={onItemDeleted}
      />
    </div>
  ));

  return isLoading ? (
    <Loader />
  ) : showEdit ? (
    <div className="box1">
      <PointsOfInterestEdit
        lang={lang}
        listingId={listingId}
        item={item}
        onFormUpdated={handleFormUpdated}
        emptyGuid={emptyGuid}
      />
    </div>
  ) : (
    <>
      <div className="cx-admin-top-actions">
        <button className="cx-button cx-button-new" onClick={onAddClick}>
          {lang.newPointsOfInterest}
        </button>
      </div>
      <div className="cx-admin-poi-list">{poiList}</div>
    </>
  );
};

export default PointsOfInterest;
