import React, { useEffect, useState } from 'react';
import './PreNotification.scss';

import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Loader, EmptyState, Breadcrumb, Card } from '../../../core/components';
import NotificationService from './Notification.service';

const PreNotification = ({ lang, history, match, location, id, title }) => {
  const breadcrumb = [{ name: lang.notifications, path: null }];
  const [notificationId] = useState(match.params.id);
  const [message, setMessage] = useState();
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (location.pathname.includes('/notifications/pre/')) {
      const service = new NotificationService();
      service
        .getPreNotification(notificationId)
        .then((x) => {
          if (x) {
            setMessage(JSON.parse(x.data.notification.message));
            setLoadingCompleted(true);
          }
        })
        .catch((e) => service.handleHttpError(e, enqueueSnackbar));
    }
  }, []);

  const onActionButtonClick = () => {
    sessionStorage.setItem('pre.path.workflow', message.notificationType);
    sessionStorage.setItem('pre.path.workflow.id', notificationId);
    history.push('/dashboard');
  };

  const getNotification = (notificationType) => {
    console.log(notificationType);
    switch (notificationType) {
      case 'Notebook':
        var msg2 = lang.notebookSharedPreNotificationInstructions2;
        msg2 = msg2.replace('#fromName', message.fromName);
        msg2 = msg2.replace('#notificationType', notificationType);
        msg2 = msg2.replace('#contentName', message.content.name);

        var msg3 = lang.notebookSharedPreNotificationInstructions3;

        return (
          <>
            <p>{lang.notebookSharedPreNotificationInstructions1.replace('#toName', message.toName)}</p>
            <p>{msg2}</p>
            <p>{msg3}</p>
          </>
        );
      default:
        return <EmptyState />;
    }
  };

  return (
    <>
      <Breadcrumb items={breadcrumb} />

      <div className="cx-pre-notification">
        {loadingCompleted ? (
          <>
            <Card>
              <div>
                {getNotification(message.notificationType)}
                <button type="button" className="cx-button cx-button-action" onClick={onActionButtonClick}>
                  {lang.continue}
                </button>
              </div>
            </Card>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state, props = {}) => {
  return {
    lang: state.app.lang,
  };
};
export default connect(mapStateToProps, null)(PreNotification);
