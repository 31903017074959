import { SEARCHPARAMS } from './search.actions';
import { SearchDto } from '../search/Search.dto';

const initialState = new SearchDto();

export const search = (state = initialState, action) => {
  // console.log('reducer', state, action);

  switch (action.type) {
    case SEARCHPARAMS:
      return action.data;
    default:
      return state;
  }
};
