import React, { useState } from 'react';
import './Tree.scss';
import PropTypes from 'prop-types';

const TreeItem = ({ item, onItemClick, buildTree, preIcon, lang, selectedId }) => {
  const [expanded, setExpanded] = useState(true);

  const handleItemClick = (e, item) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onItemClick(item);
  };

  const handleArrowClick = (e, item) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setExpanded(!expanded);
  };

  const buildSharedIcons = (item) => {
    return (
      <>
        {item.sharedWithMe || item.sharedWithOthers ? (
          <>
            {item.sharedWithMe ? (
              <i className="icon-share rotate" title={lang.sharedWithMe} />
            ) : (
              <i className="icon-share" title={lang.sharedWithOthers} />
            )}
          </>
        ) : null}
      </>
    );
  };

  const hasChildren = item.children && item.children.length > 0;
  return (
    <li key={item.id} className={selectedId === item.id ? 'cx-tree-selected' : ''}>
      {hasChildren ? (
        <>
          <i
            className={expanded ? 'icon-down-open-mini' : 'icon-right-open-mini'}
            onClick={(e) => handleArrowClick(e, item)}
          />
          <span
            className={item.isDefault ? 'cx-tree-item cx-tree-item-default' : 'cx-tree-item'}
            onClick={(e) => handleItemClick(e, item)}
          >
            {preIcon ? preIcon : null}
            <span className="cx-tree-item-name">
              {item.name}
              {buildSharedIcons(item)}
            </span>
          </span>
        </>
      ) : (
        <span
          className={item.isDefault ? 'cx-tree-item root cx-tree-item-default' : 'cx-tree-item root'}
          onClick={(e) => handleItemClick(e, item)}
        >
          {preIcon ? preIcon : null}
          <span className="cx-tree-item-name">
            {item.name}
            {buildSharedIcons(item)}
          </span>
        </span>
      )}

      {hasChildren && expanded ? <ul>{item.children.map((c, index) => buildTree(c))}</ul> : null}
    </li>
  );
};

TreeItem.propTypes = {
  item: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
  buildTree: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
};

TreeItem.defaultProps = {};

export default TreeItem;
