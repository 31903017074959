import React from 'react';
import './sideNavigation.scss';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import { SideNavigationHeader } from './header/sideNavigationHeader';

const SideNavigation = ({ history, breakPoint, onSideNavToggle }) => {
  const auth = useSelector((state) => state.auth);
  const lang = useSelector((state) => state.app.lang);
  const apps = useSelector((state) => state.accountApps.apps);

  const toggleSideNavigation = (path) => {
    if (path) {
      history.push(path);
    }

    if (!breakPoint !== 'large') {
      onSideNavToggle();
    }
  };

  const logInClick = () => {
    if (!breakPoint !== 'large') {
      onSideNavToggle();
    }

    history.push({
      pathname: '/dashboard',
    });
  };

  const logOutClick = () => {
    if (!breakPoint !== 'large') {
      onSideNavToggle();
    }

    history.push({
      pathname: '/logout',
    });
  };

  const list = apps.map(({ appId, name, link }) => (
    <ListItemButton key={appId} onClick={(x) => toggleSideNavigation(link)}>
      <ListItemText primary={name} />
    </ListItemButton>
  ));

  return (
    <>
      <aside className="cx-sidebar">
        <div className="cx-sidebar-container">
          <SideNavigationHeader lang={lang} />

          <nav>
            {auth.isAuthenticated ? (
              <List component="nav">
                <ListItemButton onClick={(x) => toggleSideNavigation('/dashboard')}>
                  <ListItemText primary={lang.dashboard} />
                </ListItemButton>
              </List>
            ) : null}

            {auth.isAuthenticated && list.length > 0 ? (
              <List
                component="nav"
                aria-labelledby="tools-subheader"
                subheader={
                  <ListSubheader component="div" id="tools-subheader">
                    {lang.tools}
                  </ListSubheader>
                }
              >
                {list}
              </List>
            ) : null}

            <List
              component="nav"
              aria-labelledby="account-subheader"
              subheader={
                <ListSubheader component="div" id="account-subheader">
                  {lang.account}
                </ListSubheader>
              }
            >
              {!auth.isAuthenticated ? (
                <>
                  <ListItemButton onClick={logInClick}>
                    <ListItemText primary={lang.logIn} />
                  </ListItemButton>
                </>
              ) : null}
              {auth.isAuthenticated ? (
                <>
                  <ListItemButton onClick={(x) => toggleSideNavigation('/account/settings')}>
                    <ListItemText primary={lang.myProfile} />
                  </ListItemButton>
                  <ListItemButton onClick={logOutClick}>
                    <ListItemText primary={lang.logOut} />
                  </ListItemButton>
                </>
              ) : null}
            </List>
          </nav>
        </div>
        <footer className="cx-sidebar-footer"></footer>
      </aside>

      <div className="cx-sidebar-overlay" onClick={toggleSideNavigation} />
    </>
  );
};

SideNavigation.propTypes = {
  history: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  onSideNavToggle: PropTypes.func.isRequired,
};

export default withRouter(SideNavigation);
