import React, { useState, useEffect } from 'react';
import './Tabs.scss';
import PropTypes from 'prop-types';

const Tabs = ({ items, onTabChanged }) => {
  const [selectedKey, setSelectedKey] = useState(null);
  const [tabContent, setTabContent] = useState(null);

  useEffect(() => {
    if (items.length > 0) {
      const item = items.filter((x) => x.isDefault)[0];
      setSelectedKey(item.key);
      setTabContent(item.content);
    }
  }, [items]);

  const onTabSelected = (tab) => {
    setSelectedKey(tab.key);
    setTabContent(tab.content);
    if (onTabChanged) {
      onTabChanged(tab.key);
    }
  };

  return (
    <div className="cx-tabs">
      <div className="cx-tab">
        {items.map((x) => (
          <button
            type="button"
            key={x.key}
            className={selectedKey === x.key ? 'cx-tab-links active' : 'tab-links'}
            onClick={() => onTabSelected(x)}
          >
            {x.icon ? <i className={x.icon} /> : null}
            <span>{x.name}</span>
          </button>
        ))}
      </div>

      <div className="cx-tab-content">{tabContent}</div>
    </div>
  );
};

Tabs.propTypes = {
  items: PropTypes.array.isRequired,
  onTabChanged: PropTypes.func,
};
Tabs.defaultProps = {};
export default Tabs;
