import React, { useEffect, useState } from 'react';
import './Login.scss';

import { useHistory } from 'react-router-dom';

import useApi from '../../../../core/useApi';

// State
import { loginSuccess } from '../redux/actions';
import { useSelector, useDispatch } from 'react-redux';

// Forms
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// UI
import { Loader, Panel } from '../../../../core/components';
import TextField from '@mui/material/TextField';

const Login = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.app.lang);
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [isLoading, setIsLoading] = useState(false);
  const { post } = useApi();

  const schema = yup
    .object({
      username: yup.string().email().required(lang.validationFieldRequired),
      password: yup.string().required(lang.validationFieldRequired).min(8),
    })
    .required();

  // Form
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const loginRequest = {
        email: data.username,
        password: data.password,
      };
      const response = await post(`account/login`, loginRequest);
      dispatch(loginSuccess(response));
      history.push('/dashboard');
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const forgotPasswordClick = () => {
    history.push('/account/email');
  };

  const accountRegisterClick = () => {
    history.push('/account/register');
  };

  if (isLoading) return <Loader />;

  return (
    <div className="cx-account-login">
      <h2>Dakity Tools {lang.login}</h2>
      <Panel title={lang.login}>
        <form id="loginForm" onSubmit={handleSubmit(onSubmit)} autoComplete="on">
          <div>
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="username"
                  type="email"
                  label={lang.email}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  inputProps={{
                    autoComplete: 'username',
                  }}
                  error={!!errors.username}
                  helperText={errors.username ? errors.username.message : ''}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="current-password"
                  label={lang.password}
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  inputProps={{
                    autoComplete: 'current-password',
                  }}
                  error={!!errors.password}
                  helperText={errors.password ? errors.password.message : ''}
                />
              )}
            />
          </div>

          <div className="cx-form-buttons">
            <button type="button" className="cx-button-link forgot-password-link" onClick={forgotPasswordClick}>
              {lang.forgotPassword}
            </button>
            <button type="submit" className="cx-button cx-button-save">
              {lang.login}
            </button>
          </div>
        </form>
      </Panel>
      <div className="account-register-link">
        <button type="button" className="cx-button-link " onClick={accountRegisterClick}>
          {lang.accountClickRegister}
        </button>
      </div>
    </div>
  );
};

export default Login;
