import React, { useState, useEffect } from 'react';
import './myProfile.scss';

import { withRouter } from 'react-router-dom';
import useApi from '../../../../core/useApi';
import { Panel, NoImagePlaceholder, Loader } from '../../../../core/components';

const MyProfile = ({ lang, history }) => {
  const { get } = useApi();
  const [item, setItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getProfile = async () => {
    setIsLoading(true);
    try {
      const profile = await get('account/profile');
      setItem(profile);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const onEdit = () => {
    history.push('/account/settings');
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {item ? (
        <Panel className="cx-dashboard-myprofile" title={lang.myProfile}>
          {item.avatar ? <img src={`${item.avatar}&s=250`} alt="Profile" /> : <NoImagePlaceholder multi={false} />}
          <div className="cx-dashboard-myprofile-info">
            <h3>{item.fullName}</h3>
            <p>{item.phone.number}</p>
            <p>
              <button className="cx-button cx-button-edit" onClick={onEdit}>
                {lang.edit}
              </button>
            </p>
          </div>
        </Panel>
      ) : null}
    </>
  );
};

export default withRouter(MyProfile);
