import React, { useEffect } from 'react';
import './Breadcrumb.scss';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';

const Breadcrumb = ({ location, items }) => {
  useEffect(() => {
    sessionStorage.setItem('path.next', location.pathname);
  }, [location]);

  const icon = <i className="icon-home" />;
  const list = items.map((item, index) => (
    <li key={item.path}>
      {item.path ? <Link to={item.path}>{index === 0 ? icon : item.name}</Link> : items.length === 1 ? icon : item.name}
    </li>
  ));

  return (
    <div className="cx-breadcrumb">
      <ul className="breadcrumb">{list}</ul>
    </div>
  );
};

Breadcrumb.propTypes = {
  location: PropTypes.any.isRequired,
  items: PropTypes.array.isRequired,
};
Breadcrumb.defaultProps = {};

export default withRouter(React.memo(Breadcrumb));
