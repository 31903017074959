import { SETAPPS } from './accountApps.actions';

const loadFromStorage = () => {
  const currentApps = localStorage.getItem(SETAPPS);
  if (currentApps === null) {
    return [];
  }
  return JSON.parse(currentApps);
};

const initialState = {
  apps: loadFromStorage(),
};

export const accountApps = (state = initialState, action) => {
  switch (action.type) {
    case SETAPPS:
      localStorage.setItem(SETAPPS, JSON.stringify(action.data));
      return {
        ...state,
        apps: action.data,
      };
    default:
      return state;
  }
};
