import React from 'react';
import './DropDownMenu.scss';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const DropDownMenu = ({ id, items, title, buttonIcon, className }) => {
  const containerBaseCssClass = className ? className : 'cx-drop-down-menu';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (items == null || items.length <= 0) return null;

  const menuItems = items.map((x) => (
    <MenuItem
      onClick={() => {
        setAnchorEl(null);
        x.action();
      }}
      key={x.text}
    >
      {x.icon ? (
        <>
          <i className={x.icon} />
          &nbsp;
        </>
      ) : null}
      {x.text}
    </MenuItem>
  ));

  return (
    <div id={id} className={containerBaseCssClass}>
      <Button
        id={`${id}-positioned-button`}
        aria-controls={open ? `${id}-positioned-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {title ? <span>{title}</span> : null}
        <i className={buttonIcon} />
      </Button>
      <Menu
        id={`${id}-positioned-menu`}
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItems}
      </Menu>
    </div>
  );
};

DropDownMenu.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

DropDownMenu.defaultProps = {
  buttonIcon: 'icon-ellipsis-v',
  title: null,
  className: null,
};

export default DropDownMenu;
