import React, { useState, useEffect } from 'react';
import './poiEdit.scss';
import { CxSelect } from '../../../../core/components';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const PointsOfInterestEdit = ({ lang, listingId, item, onFormUpdated, emptyGuid }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  // Distance
  const [distanceUnit, setDistanceUnit] = useState(item !== null ? item.distanceUnit : 1);

  const distanceUnits = [
    { id: 1, name: lang.miles },
    { id: 4, name: lang.kilometers },
  ].map(({ id, name }) => ({ name: name, value: id }));

  const handleDistanceUnitChange = (value) => {
    setDistanceUnit(value);
    setValue('distanceUnit', value, true);
  };

  // Icons
  const [icon, setIcon] = useState(item !== null ? item.icon : 'icon-dot-circle-o');
  const icons = [
    { id: 'icon-dot-circle-o', name: lang.general },
    { id: 'icon-building', name: lang.building },
    { id: 'icon-bus', name: lang.bus },
    { id: 'icon-plane', name: lang.plane },
    { id: 'icon-cutlery', name: lang.restaurants },
    { id: 'icon-info-circle', name: lang.information },
  ].map(({ id, name }) => ({ name: name, value: id }));

  const handleIconChange = (value) => {
    setIcon(value);
    setValue('icon', value, true);
  };

  // Page Load
  useEffect(() => {
    console.log(listingId);
    if (item) {
      reset({
        listingId,
        id: item.id,
        icon: item.icon,
        description: item.description,
        distance: item.distance,
        distanceUnit: item.distanceUnit,
        distanceUnitString: item.distanceUnitString,
        distanceTime: item.distanceTime,
      });
    } else {
      setValue('id', emptyGuid, true);
    }
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    if (item === null) {
      onFormUpdated('created', data);
    } else {
      onFormUpdated('updated', data);
    }
  };

  const handleEditCancelClick = () => {
    onFormUpdated('cancel', null);
  };

  return (
    <>
      <div className="cx-admin-poi-edit-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register('id')} />
          <input type="hidden" name="listingId" {...register('listingId')} defaultValue={listingId} />
          <input type="hidden" {...register('distanceUnitString')} />
          <input type="hidden" {...register('icon')} defaultValue={icon} />
          <div>
            <CxSelect
              name="icon"
              label={lang.icon}
              fullWidth
              items={icons}
              defaultValue={icon}
              onChange={handleIconChange}
            />
          </div>
          <div>
            <TextField
              name="description"
              label={lang.pointsOfInterest}
              {...register('description', { required: true })}
              variant="outlined"
              fullWidth
            />
            {errors.description && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
          </div>

          <div className="distance">
            <div>
              <TextField
                name="distance"
                label={lang.distance}
                {...register('distance', { required: { value: true } })}
                variant="outlined"
                fullWidth
              />
              {errors.distance && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>
            <div>
              <CxSelect
                name="distanceUnit"
                label={lang.distanceUnit}
                fullWidth
                items={distanceUnits}
                defaultValue={distanceUnit}
                onChange={handleDistanceUnitChange}
              />
            </div>
          </div>

          <div>
            <TextField
              name="distanceTime"
              label={lang.timeToPointOfInterestMinutes}
              {...register('distanceTime', { required: { value: true } })}
              variant="outlined"
              fullWidth
            />
            {errors.distanceTime && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
          </div>
          <div className="cx-admin-footer-actions">
            <button className="cx-button cx-button-cancel" onClick={handleEditCancelClick}>
              {lang.cancel}
            </button>
            <button type="submit" className="cx-button cx-button-save">
              {lang.save}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PointsOfInterestEdit;
