export class GrapeSearchDto {
  text = '';
}

export class GrapeSearchRequest {
  limit = 1000;
  skip = 0;
  count = 0;
  search = new GrapeSearchDto();
}
