export const configService = {
  get environment() {
    // return process.env.REACT_APP_BUILD_ENV;

    return window._env_ ? window._env_.REACT_APP_BUILD_ENV : process.env.REACT_APP_BUILD_ENV;
  },
  get appId() {
    return 'Dakity';
  },
  get appName() {
    return 'Dakity Tools';
  },
  get appUrl() {
    //return process.env.REACT_APP_APP_URL;
    return window._env_ ? window._env_.REACT_APP_APP_URL : process.env.REACT_APP_APP_URL;
  },
  get apiUrl() {
    //return process.env.REACT_APP_API_URL;
    return window._env_ ? window._env_.REACT_APP_API_URL : process.env.REACT_APP_API_URL;
  },

  get imageBaseUrl() {
    //return process.env.REACT_APP_IMAGE_URL;
    return window._env_ ? window._env_.REACT_APP_IMAGE_URL : process.env.REACT_APP_IMAGE_URL;
  },
};
