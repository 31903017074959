import React from 'react';
import './header.scss';
import ActionMenu from './actionMenu/actionMenu';

export const Header = React.memo(({ lang, isLarge, onSideNavToggle }) => {
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     var service = new DiagnosticService();
  //     if (user) {
  //       service
  //         .vivo(user.id_token)
  //         .then(() => {})
  //         .catch((e) => console.log(e));
  //     }
  //   }, 60000);
  //   // clearing interval
  //   return () => clearInterval(timer);
  // });

  const toggleSideNavigation = () => {
    onSideNavToggle();
  };

  return (
    <header className="cx-header">
      <button className="hamburger" title="Show/Hide side menu" onClick={toggleSideNavigation}>
        <i className="icon-bars" />
      </button>
      <ActionMenu lang={lang} isLarge={isLarge} />
    </header>
  );
});
