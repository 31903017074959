import React, { useEffect, useState } from 'react';
import './profile.scss';

import useApi from '../../../../core/useApi';
import { Loader, CxSelect, NoImagePlaceholder } from '../../../../core/components';

// State
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeLanguage } from '../../../redux/app/app.actions';

// MUI
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import TextField from '@mui/material/TextField';

const AccountProfile = ({ lang, changeLanguage, cultureCode }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);

  const [data, setData] = useState([]);
  const [databind, setDatabind] = useState(Date.now());

  const { get, put, upload } = useApi();

  const getProfile = async () => {
    setIsLoading(true);
    try {
      const profile = await get('account/profile');

      reset({
        email: profile.email,
        name: profile.name,
        middleName: profile.middleName ?? '',
        lastName: profile.lastName,
        title: profile.title ?? '',
        aboutMe: profile.aboutMe ?? '',
        phone: profile.phone.number,
        cultureCode: profile.cultureCode,
      });

      setData(profile);
      setSelectedCultureCode(profile.cultureCode);
      return profile;
    } finally {
      setIsLoading(false);
    }
  };

  const updateProfile = async (user) => {
    setIsLoading(true);
    try {
      await put(`account/profile`, user);
      reset();
      setDatabind(Date.now());
      if (user.cultureCode !== cultureCode) {
        changeLanguage(user.cultureCode);
      }
      enqueueSnackbar('Profile updated!', {
        variant: 'success',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const uploadProfileImage = async (data) => {
    setImageLoading(true);
    try {
      await upload(`account/profile/photo-upload`, data);

      reset();
      setDatabind(Date.now());

      enqueueSnackbar('Photo updated!', {
        variant: 'success',
      });
    } finally {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, [databind]);

  // Form
  const {
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      middleName: '',
      lastName: '',
      title: '',
      aboutMe: '',
      phone: '',
      cultureCode: '',
    },
  });

  // Culture Code Select
  const [selectedCultureCode, setSelectedCultureCode] = useState('es-PR');

  const cultureCodeList = [
    { name: 'English', value: 'en-US' },
    { name: 'Español', value: 'es-PR' },
  ];

  const handleCultureCodeChange = (value) => {
    setSelectedCultureCode(value);
    setValue('cultureCode', value, true);
  };

  const onSubmit = (data) => {
    updateProfile(data);
  };

  const myUploader = (files) => {
    //event.files == files to upload
    // console.log(event);
    const data = new FormData();
    data.append('file', files[0]);

    uploadProfileImage(data);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="cx-account-profile">
      <h2>{data.fullName}</h2>
      <div className="grid21">
        <section className="form grid21-item box1">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField {...field} id="email" type="email" label={lang.email} variant="outlined" fullWidth />
                )}
                rules={{ required: true, pattern: /^\S+@\S+$/i }}
              />
              {errors.email && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField {...field} id="name" label={lang.name} variant="outlined" fullWidth />
                )}
                rules={{ required: true, maxLength: 50 }}
              />
              {errors.name && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>
            <div>
              <Controller
                control={control}
                name="middleName"
                render={({ field }) => (
                  <TextField {...field} id="middleName" label={lang.middleName} variant="outlined" fullWidth />
                )}
                rules={{ maxLength: 50 }}
              />
              {errors.middleName && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>
            <div>
              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <TextField {...field} id="lastName" label={lang.lastName} variant="outlined" fullWidth />
                )}
                rules={{ required: true, maxLength: 50 }}
              />
              {errors.lastName && <span className="cx-form-validation-message">{lang.lastName}</span>}
            </div>

            <div>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <TextField {...field} id="phone" type="tel" label={lang.phone} variant="outlined" fullWidth />
                )}
                rules={{ required: true, minLength: 6, maxLength: 12 }}
              />
              {errors.phone && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <Controller
                control={control}
                name="title"
                render={({ field }) => (
                  <TextField {...field} id="title" label={lang.title} variant="outlined" fullWidth />
                )}
                rules={{ maxLength: 128 }}
              />
              {errors.title && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <Controller
                control={control}
                name="aboutMe"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="aboutMe"
                    label={lang.aboutMe}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                  />
                )}
                rules={{ maxLength: 1024 }}
              />
              {errors.aboutMe && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <CxSelect
                name="cultureCode"
                label={lang.language}
                fullWidth
                items={cultureCodeList}
                defaultValue={selectedCultureCode}
                onChange={handleCultureCodeChange}
              />

              {errors.cultureCode && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div className="cx-form-buttons">
              <input type="submit" className="cx-button cx-button-save" value={lang.save} />
            </div>
          </form>
        </section>
        <section className="cx-account-profile-photo grid21-item box1">
          {imageLoading ? (
            <Loader />
          ) : (
            <>
              {data.avatar ? (
                <div className="photo">
                  <img src={`${data.avatar}&s=250`} alt="Profile" />
                </div>
              ) : null}

              <div className="upload-actions">
                <Dropzone onDrop={myUploader}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps({ multiple: false })}>
                        <input {...getInputProps()} />
                        {!data.avatar ? <NoImagePlaceholder multi={false} /> : null}
                        <p>{lang.dropProfilePhoto}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </>
          )}
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props = {}) => {
  return {
    lang: state.app.lang,
    cultureCode: state.app.cultureCode,
  };
};

const mapDispatchToProps = {
  changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountProfile));
