import React from 'react';

export const SlideDot = props => {
  const onDotClick = () => {
    props.onDotClick(props.index);
  };

  return (
    <>
      <span className={`dot dot-${props.id}`} onClick={onDotClick}></span>
    </>
  );
};
