import React, { useEffect, useState, useCallback } from 'react';
import './todoEdit.scss';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import TodoService from './todo.service';
import CategoryService from '../../../core/services/category.service';

import TextField from '@mui/material/TextField';
import { CxSelect, Loader } from '../../../core/components';

const TodoEdit = ({ lang, todoId, editTodoFormEventHandler }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const databind = useCallback(() => {
    const catService = new CategoryService();
    catService
      .getCategories()
      .then((x) => {
        if (x) {
          setCategories(x.data);

          if (x.data) {
            setCategoryId(x.data[0].id);
            setValue('categoryId', x.data[0].id, true);
          }

          if (!todoId) {
            setLoadingCompleted(true);
          }
        }
      })
      .catch((er) => catService.handleHttpError(er, enqueueSnackbar));

    if (todoId) {
      const todoService = new TodoService();

      todoService
        .getTodo(todoId)
        .then((x) => {
          reset({
            id: x.data.id,
            title: x.data.title,
            categoryId: x.data.categoryId,
          });
          setCategoryId(x.data.categoryId);
          setLoadingCompleted(true);
        })
        .catch((er) => todoService.handleHttpError(er, enqueueSnackbar));
    }
  }, []);

  useEffect(() => {
    databind();
  }, [databind]);

  const onSaveClick = (data) => {
    const todoService = new TodoService();

    let request = null;
    if (data.id === '') {
      request = todoService.createTodo(data);
    } else {
      request = todoService.updateTodo(data);
    }
    request
      .then((x) => {
        reset();
        enqueueSnackbar(`'${data.title}' ${lang.saved}`, {
          variant: 'success',
        });
        editTodoFormEventHandler('saved');
      })
      .catch((er) => todoService.handleHttpError(er, enqueueSnackbar));
  };

  const onCancelClick = () => {
    reset();
    editTodoFormEventHandler('canceled');
  };

  // Category Select
  const categoryList = categories.map(({ id, name }) => ({ name: name, value: id }));

  const handleCategoryChange = (value) => {
    console.log(value);
    setCategoryId(value);
    setValue('categoryId', value, true);
  };

  return (
    <div className="cx-todo-list-edit">
      {loadingCompleted ? (
        <section className="form">
          <form onSubmit={handleSubmit(onSaveClick)}>
            <input type="hidden" {...register('id')} />
            <div>
              <TextField
                id="title"
                name="title"
                type="text"
                label={lang.title}
                {...register('title', {
                  required: { value: true, message: lang.validationFieldRequired },
                })}
                variant="outlined"
                fullWidth
              />

              {errors.title && <span>{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <CxSelect
                name="categoryId"
                label={lang.category}
                fullWidth
                items={categoryList}
                defaultValue={categoryId}
                onChange={handleCategoryChange}
              />
            </div>

            <div className="cx-admin-footer-actions">
              <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
                {lang.cancel}
              </button>
              <button type="submit" className="cx-button cx-button-save">
                {lang.save}
              </button>
            </div>
          </form>
        </section>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TodoEdit;
