import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CxSelect = ({ name, label, defaultValue, items, onChange, ...props }) => {
  const labelId = `${name}-label`;

  const selectItems = items.map((x) => (
    <MenuItem key={x.value} value={x.value}>
      {x.name}
    </MenuItem>
  ));

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <>
      <FormControl {...props}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          variant="outlined"
          margin="none"
          key={`select-${defaultValue}`}
          labelId={labelId}
          id={`${name}-select`}
          label={name}
          value={defaultValue}
          onChange={handleChange}
        >
          {selectItems}
        </Select>
      </FormControl>
    </>
  );
};

CxSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  items: PropTypes.array.isRequired,
};
CxSelect.defaultProps = {};
export default CxSelect;

/*

// CODE
  const [selectedCultureCode, setSelectedCultureCode] = useState('es-PR');

  const cultureCodeList = [
    { name: 'English', value: 'en-US' },
    { name: 'Español', value: 'es-PR' },
  ];

  OR

  const notebookList = notebooks.filter((x) => x.id !== notebook.id).map(({ id, name }) => ({ name: name, value: id }));


// HTML
 <CxSelect
    name="cultureCode"
    label={lang.language}
    fullWidth
    items={cultureCodeList}
    defaultValue={selectedCultureCode}
    onChange={handleCultureCodeChange}
  />

*/
