const lgMinWidth = 1200;
const mdMinWidth = 992;
const smMinWidth = 768;
const xsMaxWidth = smMinWidth - 1;

const isXSmall = () => window.matchMedia('screen and (max-width:767px)').matches;
const isSmall = () => window.matchMedia('screen and (max-width:992px)').matches;
const isSmallOrGreater = () => window.matchMedia('screen and (min-width: 768px) ').matches;
const isMedium = () => window.matchMedia('screen and (min-width: 992px) and (max-width:1199px)').matches;
const isMediumOrGreater = () => window.matchMedia('screen and (min-width: 992px) ').matches;
const isSmallerThanLarge = () => window.matchMedia('screen and (max-width:1199px)').matches;
const isLarge = () => window.matchMedia('screen and (min-width:1200px)').matches;
const isPortrait = () => window.matchMedia('(orientation: portrait)').matches;

export default {
  xsMaxWidth,
  smMinWidth,
  isXSmall,
  isSmall,
  isSmallOrGreater,
  mdMinWidth,
  isMedium,
  lgMinWidth,
  isLarge,
  isPortrait,
  isMediumOrGreater,
  isSmallerThanLarge,
};
