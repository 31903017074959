import ServiceBase from '../../../core/services/serviceBase';

export default class ListingAdminPhotoService extends ServiceBase {
  get = (id) => super.http.get(`admin/listings/${id}/photos`);
  upload = (id, data: FormData) => {
    return super.http.post(`admin/listings/${id}/photos`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
  update = (listingId, data) => super.http.put(`admin/listings/${listingId}/photos`, data);
  delete = (listingId, id) => super.http.delete(`admin/listings/${listingId}/photos/${id}`);
}
