class ListingMetrics {
    pricePerSquareFootage = 0;
    pricePerSquareMeter = 0;
    pricePerUnit = 0;
    incomePerSquareFootage = 0;
    incomePerSquareMeter = 0;
    incomePerUnit = 0;
    expensesPerSquareFootage = 0;
    expensesPerSquareMeters = 0;
    expensePerUnit = 0;
    propertyNoi = 0;
    noiPerSquareFeet = 0;
    noiPerSquareMeter = 0;
    noiPerUnit = 0;
    operatingExpenseRatio = 0;
    noiProfitMarginRatio = 0;
    paybackPeriod = 0;
    parkingRatio = 0;
  }

  export default ListingMetrics;