import React from 'react';
import './quickActions.scss';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Panel } from '../../../../core/components';

const QuickActions = ({ lang, history, apps }) => {
  const navigateTo = (path) => {
    history.push(path);
  };

  return apps.length > 0 ? (
    <Panel className="cx-dashboard-quick-actions" title={lang.quickActions} open={true}>
      <ul>
        {/* Listings */}
        {apps.findIndex((x) => x.name.includes('Listings')) >= 0 ? (
          <>
            <li onClick={(x) => navigateTo('/listings/new')}>{lang.addListing}</li>
            <li onClick={(x) => navigateTo('/listings-dashboard')}>{lang.listingSearch}</li>
          </>
        ) : null}

        {/* BEER */}
        {apps.findIndex((x) => x.name.includes('Beer')) >= 0 ? (
          <>
            <li onClick={(x) => navigateTo('/beers/new')}>{lang.newBeer}</li>
          </>
        ) : null}

        {/* WINE */}
        {apps.findIndex((x) => x.name.includes('Wine')) >= 0 ? (
          <>
            <li onClick={(x) => navigateTo('/wines/new')}>{lang.newWine}</li>
          </>
        ) : null}
      </ul>
    </Panel>
  ) : null;
};

const mapStateToProps = (state, props = {}) => {
  return {
    apps: state.accountApps.apps,
  };
};

export default connect(mapStateToProps, null)(withRouter(QuickActions));
