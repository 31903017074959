import React from 'react';
import './poiItem.scss';
const PointOfInterestItem = ({ lang, item, isAdmin, onSelected, onDeleted }) => {
  return (
    <div className="cx-admin-poi-list-item">
      <div className="cx-admin-poi-list-item-content">
        <div className="icon">
          <i className={item.icon} />
        </div>
        <div className="cx-admin-poi-list-item-content-body">
          <div className="title">{item.description}</div>

          <div className="specs">
            <div>
              <i className="icon-clock" /> {`${item.distanceTime} ${lang.min}`}
            </div>
            <div>
              <i className="icon-icon-pinpoint" /> {`${item.distance} ${item.distanceUnitString}`}
            </div>
          </div>
        </div>
      </div>

      {isAdmin ? (
        <div className="cx-admin-footer-actions">
          <button className="cx-button cx-button-edit" onClick={() => onSelected(item)}>
            <i className="icon-pencil" /> {lang.edit}
          </button>
          <button className="cx-button cx-button-delete" onClick={() => onDeleted(item)}>
            <i className="icon-trash-2" /> {lang.delete}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default PointOfInterestItem;
