import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

// State
import { useSelector, useDispatch } from 'react-redux';
import { loginSuccess } from '../components/features/account/redux/actions';

import { configService } from './services/config.service';

const useOpsApi = () => {
  const { enqueueSnackbar } = useSnackbar();
  const auth = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const genericError = 'API request responded with error!';

  const ParseErrorsArray = (errors) => {
    let title = genericError;
    let details = [];

    if (errors.length < 1) {
      return [title, details];
    }

    title = errors.length > 1 ? 'API response fail with multiple errors.' : errors[0].message;

    details = errors.map((x, index) => {
      return {
        key: index,
        message: x.message,
        type: x.messageType,
      };
    });

    return [title, details];
  };

  const ParseModelStateErrors = (errors) => {
    //   // `errors` is the model state from the .NET API
    //   for (const [key, messages] of Object.entries(errors)) {
    //     console.log(`Field ${key}:`);
    //     if (Array.isArray(messages)) {
    //         messages.forEach(message => console.log(`  - ${message}`));
    //     } else {
    //         console.log(`  - ${messages}`);
    //     }
    // }

    let title = genericError;
    let details = [];

    if (!errors) {
      return [title, details];
    }

    title = errors.length > 1 ? 'API response fail with multiple errors.' : '';

    Object.keys(errors).forEach((e, index) => {
      const firstMessage = errors[e];
      const messages = errors[e].map((x) => x).join(',');

      if (index === 0) title = firstMessage;

      details.push({
        key: e,
        message: messages,
        type: 'error',
      });
    });

    return [title, details];
  };

  // Supported Verbs
  const GET = 'GET';
  const POST = 'POST';
  const PUT = 'PUT';
  const DELETE = 'DELETE';
  const UPLOAD = 'UPLOAD';

  // Operations
  const get = (path) => http(path, GET, null);
  const post = (path, body) => http(path, POST, body);
  const put = (path, body) => http(path, PUT, body);
  const remove = (path) => http(path, DELETE);
  const upload = (path, body) => http(path, UPLOAD, body);

  const http = useCallback(
    async (apiRoute, method, body = null) => {
      try {
        const url = `${configService.apiUrl}${apiRoute}`;

        const myHeaders = new Headers();
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('x-client-id', configService.appId);

        const verb = method === 'UPLOAD' ? 'POST' : method;

        let payload = null;

        if (method === UPLOAD) {
          // When upload let the browser set the Content-Type.
          // When upload body is not JSON.stringify.
          payload = body;
        }

        if (method === POST || method === PUT) {
          myHeaders.append('Content-Type', 'application/json');
          payload = JSON.stringify(body);
        }

        if (auth && auth.token.access_token) {
          myHeaders.append('Authorization', `Bearer ${auth.token.access_token}`);
        }

        // Call API
        const response = await fetch(url, {
          method: verb,
          headers: myHeaders,
          body: payload,
        });

        // NOT SUCCESS
        if (!response.ok) {
          if (response.status === 401) {
            const invalidAuthResponse = await response.json();

            if (invalidAuthResponse.data == 'TOKEN_EXPIRED') {
              try {
                const refreshToken = auth.token.refresh_token;
                const newAccessToken = await tryRefreshToken(refreshToken);

                // Update state with new token
                const newUser = {
                  token: {
                    access_token: newAccessToken,
                    token_type: 'Bearer',
                    refresh_token: refreshToken,
                  },
                  profile: auth.profile,
                };

                dispatch(loginSuccess(newUser));

                // Retry API call with the original request and the new access token
                myHeaders.set('Authorization', `Bearer ${newAccessToken}`);

                const retryResponse = await fetch(url, {
                  method: verb,
                  headers: myHeaders,
                  body: payload,
                });

                if (retryResponse.status === 401) throw Error('TOKEN_EXPIRED');

                console.log('=> Token refreshed');

                if (retryResponse.status === 204) return Promise.resolve(null);

                try {
                  return await retryResponse.json();
                } catch {
                  // 200 with empty body
                  return Promise.resolve(null);
                }
              } catch (refreshError) {
                // Logout UI when API respond with invalid/expired token.
                // INVALID_REFRESH_TOKEN or any other case.
                enqueueSnackbar('Session expired. Please login again.', {
                  variant: 'warning',
                  preventDuplicate: true,
                });

                setTimeout(() => {
                  window.location = window.location.origin + '/logout';
                }, 500);

                return Promise.reject();
              }
            }

            if (invalidAuthResponse.data == 'INVALID_AUTH') {
              return Promise.reject('Invalid username or password!');
            }

            // Logout UI when API respond with invalid/expired token.
            // INVALID_REFRESH_TOKEN or any other case.
            enqueueSnackbar('Session expired. Please login again.', {
              variant: 'warning',
              preventDuplicate: true,
            });

            setTimeout(() => {
              window.location = window.location.origin + '/logout';
            }, 500);

            return Promise.reject();
          }

          const isResponseJson = response.headers?.get('content-type')?.split(';').includes('application/json');

          // if (ex.hasOwnProperty('data') && ex.hasOwnProperty('messages')) {
          //   if (ex.messages.length > 0) {
          //     ex.messages.map((x) =>
          //       enqueueSnackbar(x.message, {
          //         variant: x.messageType.toLowerCase(),
          //       })
          //     );
          //   }
          // }

          const getMessageFromErrors = async (response) => {
            const errors = await response.json();

            let title = genericError;
            let details = [];

            // TODO Use photo upload when fixing errors logic.
            // console.log(errors);

            // Service Result
            if (errors.hasOwnProperty('errorMessages')) {
              if (errors.messages.length > 0) {
                title = errors.messages[0].message;
                details = [];

                errors.messages.map((x) => {
                  // console.log(x);

                  enqueueSnackbar(x.message, {
                    variant: x.messageType.toLowerCase(),
                    preventDuplicate: true,
                  });

                  details.push({
                    key: x.id,
                    message: x.message,
                    type: x.messageType,
                  });
                });
              }
              return { title, details };
            }

            // Model binder
            if (errors.hasOwnProperty('errors')) {
              let [titleN, detailsN] = ParseModelStateErrors(errors.errors);
              title = titleN;
              details = detailsN;
              return { title, details };
            }

            // Other

            if (!errors) {
              return { title, details };
            }

            if (!errors.hasErrors && errors.hasErrors === true) {
              let [title, details] = ParseErrorsArray(errors.messages);
              return { title, details };
            }

            // if (!errors.errors && errors.errors.length > 0) {
            //   let [title, details] = ParseModelStateErrors(errors.errors);
            //   return { title, details };
            // }

            if (errors.Message) {
              title = errors.Message ?? genericError;
            }

            return { title, details };
          };

          // Capture API errors
          const errorMessage = isResponseJson
            ? (await getMessageFromErrors(response)) ?? { title: response.statusText }
            : { title: genericError };

          throw new Error(errorMessage && errorMessage.title ? errorMessage.title : 'Api request error', {
            cause: errorMessage,
          });
        }

        // SUCCESS
        if (response.status === 204) return Promise.resolve(null);

        try {
          return await response.json();
        } catch {
          // 200 with empty body
          return Promise.resolve(null);
        }
      } catch (error) {
        // Network Error
        if (
          error.name === 'TypeError' &&
          (error.message.includes('NetworkError') || error.message.includes('Failed to fetch'))
        ) {
          enqueueSnackbar('Network error: Please check your internet connection.', {
            variant: 'error',
            preventDuplicate: true,
          });
        }

        if (error.cause && error.cause.title) {
          enqueueSnackbar(error.cause.title, {
            variant: 'error',
            preventDuplicate: true,
          });
        }

        throw error;
      }
    },
    [enqueueSnackbar]
  );

  const tryRefreshToken = async (refreshToken) => {
    try {
      const url = `${configService.apiUrl}account/login/refresh`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refreshToken }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          var responseError = await response.json();
          throw new Error(responseError.data);
        }
        throw new Error('Refresh token failed');
      }

      const data = await response.json();
      return data.newAccessToken;
    } catch (error) {
      console.error('Refresh token error:', error);
      throw error;
    }
  };

  return {
    http,
    get,
    post,
    put,
    del: remove,
    upload,
  };
};

export default useOpsApi;

/* EXAMPLE IMPORTS

import useOpsApi from "../../../../core/useApi";

  const { post } = useOpsApi();

*/

/* EXAMPLE CALLING API

await post(`metrics`, { name: "" });

*/

/* EXAMPLE CONSUMING ERRORS

  const getDetails = () =>
    data.details.map((x) => (
      <p key={x.key}>
        {x.key} : {x.messages}
      </p>
    ));

  return data ? (
    <div className={"cvi-alert " + color()}>
      <h3>{data.title}</h3>
      {data.details ? (
        <div className="cvi-alert-details">{getDetails()}</div>
      ) : null}
    </div>
  ) : null;

*/
