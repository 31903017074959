export class SearchDto {
  searchType = 'sale';
  listingTypes = [];
  priceRange = { from: 5000, to: 5000000, unit: 'total' };
  lotSizeRange = { from: 0, to: 25000 };
  buildingSizeRange = { from: 0, to: 25000 };
  numberOfFloorsRange = { from: 0, to: 10 };
  numberOfParkingSpacesRange = { from: 0, to: 25 };

  // Location
  fullAddress = '';
  cityId = '';
  zipCode = '';
  listingsWithinMiles = 0;

  // Defaults
  defaultRegionId = 'E335308C-399F-4088-93EE-AF52E52A992D';
  showMyFavorites = false;
  searchMode = 'basic';
}

export class ListingSearchRequest {
  limit = 50;
  skip = 0;
  count = 0;
  search = new SearchDto();
}
