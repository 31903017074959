import React, { useState } from 'react';
import './CxSlider.scss';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const PrettoSlider = styled(Slider)({
  color: '#db2323',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const CxSlider = ({ step, min, max, defaultValue, onSliderValueChanged, valueText, labelText }) => {
  const [value, setValue] = useState(defaultValue);

  const handleOnChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOnChangeCommitted = (event, newValue) => {
    onSliderValueChanged(value);
  };

  return (
    <div className="cx-rating">
      <div className="cx-rating-labels">
        <label>{labelText}</label>
        <label>{value} of 5</label>
      </div>

      <PrettoSlider
        value={value}
        defaultValue={defaultValue}
        getAriaValueText={valueText}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        onChange={handleOnChange}
        onChangeCommitted={handleOnChangeCommitted}
        step={step}
        min={min}
        max={max}
      />
    </div>
  );
};

CxSlider.propTypes = {
  step: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onSliderValueChanged: PropTypes.func.isRequired,
  valueText: PropTypes.func,
  labelText: PropTypes.string,
};

export default CxSlider;
