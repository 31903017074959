import React, { useState, useRef } from 'react';
import './WineItem.scss';
import Rating from '@mui/material/Rating';
import { Modal } from '../../../core/components';
import { styled } from '@mui/material/styles';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

const WineItem = ({ lang, item, onWineItemClick, onWineItemLinkClick }) => {
  const [fullImage, setFullImage] = useState();
  const handleItemClick = () => {
    onWineItemClick(item);
  };

  const showFullImage = useRef();

  const handleItemPhotoClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setFullImage(item.imageUrl);
    showFullImage.current.toggleModal(true);
  };
  const handleModalClose = (e) => {
    setFullImage(null);
    showFullImage.current.toggleModal(false);
  };

  const handleGrapeClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onWineItemLinkClick(item.grape);
  };

  const handleStyleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    console.log(item.style);
    onWineItemLinkClick(item.style);
  };

  return (
    <>
      <div className="cx-wines-item" onClick={handleItemClick}>
        <div className="cx-wines-item-image" onClick={handleItemPhotoClick}>
          {item.imageUrl ? <img src={`${item.imageUrl}&s=125x150`} alt="wine primary" /> : <i className="icon-glass" />}
        </div>
        <div className="cx-wines-item-info">
          <h2>
            <span className="wine-name">{item.name}</span>
            {item.alcoholPerVolume ? <span className="wine-abv">{item.alcoholPerVolume}%</span> : null}
          </h2>
          <StyledRating
            name="customized-color"
            defaultValue={item.rating}
            getLabelText={(value) => `${value} Wine${value !== 1 ? 's' : ''}`}
            precision={0.5}
            readOnly={true}
            icon={<i className="icon-glass" />}
            emptyIcon={<i className="icon-glass" />}
          />
          <small className="grape" onClick={handleGrapeClick}>
            {item.grape}
          </small>
          <small className="wine-style" onClick={handleStyleClick}>
            {item.style}
          </small>
          <small className="wine-when">
            {lang.firstHad}: {new Date(item.createDate).toLocaleString()}
          </small>
        </div>
        <Modal ref={showFullImage} onModalClosed={handleModalClose}>
          {fullImage ? <img src={fullImage} alt="wine full size" /> : null}
        </Modal>
      </div>
    </>
  );
};

export default WineItem;
