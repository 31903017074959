import { SETRESOLUTIONWIDTH, SETRESOLUTIONHEIGHT } from './responsive.actions';

import responsiveService from './../../../core/responsive';

const calculateBreakPoint = (width, currentBreakPoint) => {
  if (width < responsiveService.smMinWidth) {
    if (currentBreakPoint !== 'xSmall') {
      return 'xSmall';
    }
  }

  if (width > responsiveService.smMinWidth && width < responsiveService.mdMinWidth) {
    if (currentBreakPoint !== 'small') {
      return 'small';
    }
  }

  if (width > responsiveService.mdMinWidth && width < responsiveService.lgMinWidth) {
    if (currentBreakPoint !== 'medium') {
      return 'medium';
    }
  }

  if (width >= responsiveService.lgMinWidth) {
    if (currentBreakPoint !== 'large') {
      return 'large';
    }
  }

  return currentBreakPoint;
};

const initialState = {
  breakPoint: calculateBreakPoint(window.innerWidth, 'xSmall'),
  width: window.innerWidth,
  height: window.innerHeight,
};

export const responsive = (state = initialState, action) => {
  switch (action.type) {
    case SETRESOLUTIONWIDTH:
      const bpw = calculateBreakPoint(action.data, state.breakPoint);

      return {
        ...state,
        width: action.data,
        breakPoint: bpw, // We set the breakpoint here because we need to with to calculate it.
      };
    case SETRESOLUTIONHEIGHT:
      return {
        ...state,
        height: action.data,
      };
    default:
      return state;
  }
};
