import ServiceBase from '../../../core/services/serviceBase';

export default class BeerService extends ServiceBase {
  search = (request) => super.http.get(`beers/search?${this._parseQuery(request)}`);
  get = (id) => super.http.get(`beers/${id}`);
  create = (data) =>
    super.http.post('beers', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  update = (id, data) =>
    super.http.put(`beers/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  delete = (id) => super.http.delete(`beers/${id}`);

  _parseQuery = ({ limit, skip, search }) => {
    let query = '';

    if (search.text) {
      query += `&searchText=${encodeURIComponent(search.text)}`;
    }

    query += `&limit=${limit}&skip=${skip}`;
    query += `&sort=${search.sort}.${search.sortDirection}`;

    //  console.log(query);

    return query;
  };

  tempImageUpload = (data) => {
    return super.http.post(`core/temp-image-upload?width=350&height=350`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
}
