import React from 'react';
import './error.page.scss';

import { withRouter } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

const Error = ({ lang, history }) => {
  return (
    <div className="cx-error-page">
      <h2>{lang.unexpectedErrorOcurred}</h2>
      <button type="button" className="cx-button cx-button-action" onClick={() => history.push('/dashboard')}>
        {lang.dashboard}
      </button>
    </div>
  );
};

const mapStateToProps = (state, props = {}) => {
  return {
    lang: state.app.lang,
  };
};
export default connect(mapStateToProps, null)(withRouter(Error));
