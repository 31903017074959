import React, { useEffect, useState } from 'react';
import './accountApps.scss';

import useApi from '../../../../core/useApi';

// State
import { connect } from 'react-redux';

import RandomService from '../../../../core/services/random.service';
import { setApps } from '../../../redux/accountApps/accountApps.actions';

// Mui
import Switch from '@mui/material/Switch';

const AccountApps = ({ lang, setApps }) => {
  const { get, put } = useApi();

  const [items, setItems] = useState([]);
  const [reload, setReload] = useState(0);

  const getApps = async () => {
    const response = await get('apps');
    setItems(response);
    const activeApps = response.filter((x) => response.isActive);
    setApps(activeApps);
  };

  const changeStatus = async (app) => {
    const updatedApp = { ...app };
    updatedApp.isActive = !app.isActive;

    await put('account/apps/status', updatedApp);
    setReload(RandomService.getRandomString(10));
  };

  useEffect(() => {
    getApps();
  }, [reload]);

  const list = items.map((item) => (
    <div className="cx-user-apps-item" key={item.appId}>
      <div className="cx-account-apps-switch-container">
        <span>{item.isActive ? lang.enabled : lang.disabled}</span>
        <Switch
          checked={item.isActive}
          onChange={(e) => changeStatus(item)}
          name={item.name}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </div>
      <div className={'cx-user-apps-item-container ' + item.cssClass} key={item.appId}>
        <h2>
          <i className={item.icon}></i>
          <span>{item.name}</span>
        </h2>
        <p>{item.description}</p>
      </div>
    </div>
  ));

  return (
    <div className="cx-account-apps">
      <div className="cx-user-apps">{list}</div>
    </div>
  );
};

const mapDispatchToProps = {
  setApps,
};

export default connect(null, mapDispatchToProps)(AccountApps);
