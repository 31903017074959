import React, { useEffect, useState } from 'react';
import './NoteContainer.scss';

import responsive from '../../../../core/responsive';
import { Breadcrumb, Loader, EmptyState } from '../../../../core/components';
import useApi from '../../../../core/useApi';
import { helpers } from '../../../../core/helpers';
import randomService from '../../../../core/services/random.service';

import NotesNav from '../NotesNav';
import NoteList from './NoteList';
import NoteEdit from './NoteEdit';

// State
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setWorkingNotebook } from '../../../redux/notes/notes.actions';

// Mui
import { useSnackbar } from 'notistack';

const NoteContainer = ({ lang, history, workingNotebook, setWorkingNotebook, breakPoint }) => {
  const { get, post, put, del } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.notes, path: null },
  ];

  const [showEditor, setShowEditor] = useState(responsive.isSmallOrGreater());
  const [isCompactMode] = useState(!responsive.isSmallOrGreater());
  const [databind, setDatabind] = useState();

  // Screen Size
  const bodyHeightPadding = 102;
  const [noteContainerHeight, setNoteContainerHeight] = useState(window.innerHeight - bodyHeightPadding);

  useEffect(() => {
    const handleResize = () => {
      setNoteContainerHeight(window.innerHeight - bodyHeightPadding);
    };

    window.addEventListener('resize', handleResize);

    // Initial calculation
    handleResize();

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Notebooks
  const [notebooks, setNotebooks] = useState([]);

  const getNotebook = async () => {
    setIsLoading(true);
    try {
      const response = await get('notebook');

      setNotebooks(response);
      if (response.length > 0) {
        const defaultNotebook = response.filter((x) => x.isDefault)[0];

        if (workingNotebook === null) {
          setWorkingNotebook(defaultNotebook);
        }

        setDatabind(randomService.getRandomString(20));
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(false);
    getNotebook();
  }, []);

  // Notebook Notes
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [selectedNote, setSelectedNote] = React.useState(null);

  const getNotebookNotes = async (notebookId) => {
    setIsLoading(true);
    try {
      const response = await get(`notebook/${notebookId}/notes`);

      setNotes(response);
      setFilteredNotes(response);
      calculateNoteToDisplay(response);
      setIsLoading(true);
      if (history.location.state === 'onNewNoteClick') {
        handleNewNoteClick();
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (notebooks.length > 0 && workingNotebook != null) {
      setIsLoading(false);
      getNotebookNotes(workingNotebook.id);
    }
  }, [workingNotebook, databind]);

  const getNote = async (notebookId, id) => {
    const response = await get(`notebook/${notebookId}/notes/${id}`);
    setSelectedNote(response);
    return response;
  };

  const calculateNoteToDisplay = (notes) => {
    if (!isCompactMode) {
      if (notes.length > 0) {
        const selectedNote = notes[0];

        getNote(selectedNote.notebookId, selectedNote.id);
      } else {
        setSelectedNote({
          id: '',
          title: '',
          body: '',
          notebookId: workingNotebook.id,
          isOwner: true,
        });
      }
    }
  };

  const handleAutoSave = (note) => {
    saveNote(note, false);
  };

  const newNotebooksClick = () => {
    history.push('/notebooks');
  };

  const handleNewNoteClick = () => {
    setSelectedNote({
      id: '',
      title: '',
      body: '',
      notebookId: workingNotebook.id,
      isOwner: true,
    });
    setShowEditor(true);
  };
  const handleNotebookSelected = (id) => {
    const defaultNotebooks = notebooks.filter((x) => x.id === id);

    if (defaultNotebooks.length == 0) {
      enqueueSnackbar(lang.noteUnableToDetermineDefaultNotebook, {
        variant: 'error',
      });
      return;
    }

    const defaultNotebook = defaultNotebooks[0];

    if (!defaultNotebook) {
      enqueueSnackbar(lang.noteUnableToDetermineDefaultNotebook, {
        variant: 'error',
      });
    }

    if (defaultNotebook !== null && defaultNotebook.id !== workingNotebook.id) {
      setWorkingNotebook(defaultNotebook);
      setSelectedNote(null);
      setShowEditor(false);
      setDatabind(randomService.getRandomString(20));
    }
  };
  const handleAllNotesClick = () => {
    setDatabind(randomService.getRandomString(20));
  };
  const handleNoteSelected = (note) => {
    getNote(note.notebookId, note.id);
    setShowEditor(true);
  };

  const saveNote = async (note, showConfirmation) => {
    setIsLoading(true);
    try {
      await put(`notebook/${workingNotebook.id}/notes/${note.id}`, note);

      // Update array
      note.modifyDate = new Date();
      setSelectedNote(note);

      const updatedNotes = notes.filter((x) => x.id !== note.id);
      updatedNotes.unshift(note);

      setNotes(updatedNotes);
      setFilteredNotes(updatedNotes);

      if (showConfirmation) {
        enqueueSnackbar(lang.noteSaved, {
          variant: 'success',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditNoteSaved = async (note) => {
    setIsLoading(true);
    try {
      if (note.id === '') {
        const response = await post(`notebook/${workingNotebook.id}/notes`, note);

        const updatedNotes = [...notes];
        updatedNotes.unshift(response);
        setNotes(updatedNotes);
        setFilteredNotes(updatedNotes);
        setSelectedNote(response);
        enqueueSnackbar(lang.noteCreated, {
          variant: 'success',
        });
      } else {
        saveNote(note, true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditNoteCancel = () => {
    setSelectedNote(null);
    setShowEditor(false);
  };

  const handleEditNoteDelete = async (note) => {
    setIsLoading(true);
    try {
      await del(`notebook/${workingNotebook.id}/notes/${note.id}`);

      const updatedNotes = notes.filter((x) => x.id !== note.id);
      setNotes(updatedNotes);
      setFilteredNotes(updatedNotes);
      calculateNoteToDisplay(updatedNotes);
      setSelectedNote(null);
      setShowEditor(false);
      enqueueSnackbar(lang.noteDeleted, {
        variant: 'success',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Search
  const handleSearch = (searchRequest) => {
    if (searchRequest.search.text === null) {
      setFilteredNotes(notes);
    } else {
      const newItems = notes.filter((x) => x.title.toLowerCase().includes(searchRequest.search.text.toLowerCase()));
      setFilteredNotes(newItems);
    }
  };

  // Sorting
  const handleSort = (request) => {
    const newItems = notes.sort((a, b) => {
      if (request.search.sort === 'title') {
        if (request.search.sortDirection === 'asc') {
          return helpers.sortTextAsc(a.title, b.title);
        }
        return helpers.sortTextDesc(a.title, b.title);
      }

      if (request.search.sortDirection === 'asc') {
        return helpers.sortDateTimeAsc(a.modifyDate, b.modifyDate);
      }
      return helpers.sortDateTimeDesc(a.modifyDate, b.modifyDate);
    });

    setFilteredNotes(newItems);
  };

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <div className="cx-notes" style={{ height: noteContainerHeight + 'px' }}>
        {notebooks.length > 0 ? (
          <NotesNav
            lang={lang}
            history={history}
            notebooks={notebooks}
            selectedNotebook={workingNotebook}
            onNotebookSelected={handleNotebookSelected}
            onNewNoteClick={handleNewNoteClick}
            onAllNotesClick={handleAllNotesClick}
            breakPoint={breakPoint}
          />
        ) : null}

        <div className="cx-notes-container">
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {notebooks.length === 0 ? (
                  <EmptyState
                    title={lang.welcomeToNotesTitle}
                    description={lang.welcomeToNotesBody}
                    onActionClick={newNotebooksClick}
                    actionText={lang.newNotebook}
                  />
                ) : (
                  <>
                    {(isCompactMode && !showEditor) || !isCompactMode ? (
                      <>
                        {notes.length === 0 ? (
                          <EmptyState title={lang.noNotesTitle} />
                        ) : (
                          <div className="cx-notes-container-list">
                            <NoteList
                              lang={lang}
                              items={filteredNotes}
                              selectedItem={selectedNote}
                              onNoteSelected={handleNoteSelected}
                              onSearch={handleSearch}
                              onSort={handleSort}
                            />
                          </div>
                        )}
                      </>
                    ) : null}

                    {selectedNote ? (
                      <NoteEdit
                        lang={lang}
                        note={selectedNote}
                        onNoteSaved={handleEditNoteSaved}
                        onNoteAutoSave={handleAutoSave}
                        onNoteCancel={handleEditNoteCancel}
                        onNoteDelete={handleEditNoteDelete}
                        noteContainerHeight={noteContainerHeight}
                      />
                    ) : null}
                  </>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, props = {}) => {
  return {
    lang: state.app.lang,
    workingNotebook: state.notes.workingNotebook,
    breakPoint: state.responsive.breakPoint,
  };
};
const mapDispatchToProps = {
  setWorkingNotebook,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NoteContainer));
