import React from 'react';
import './notFound.scss';

import { withRouter, Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

const NotFound = props => {
  return (
    <div className="cx-not-found">
      <h1>404</h1>
      <h3>{props.lang.notFound}</h3>
      <Link to="/">
        <span>{`> ${props.lang.home} <`}  </span>
      </Link>
    </div>
  );
};

const mapStateToProps = (state, props = {}) => {
  return {
    lang: state.app.lang
  };
};
export default connect(
  mapStateToProps,
  null
)(withRouter(NotFound));
