import React from 'react';
import './EmptyState.scss';
import PropTypes from 'prop-types';

const EmptyState = ({ title, description, onActionClick, actionText }) => {
  const handleActionClick = () => {
    onActionClick();
  };
  return (
    <div className="cx-empty-state">
      <h2>{title}</h2>
      {description ?? <p>{description}</p>}
      {actionText !== '' ? (
        <div>
          <button type="button" className="cx-button cx-button-action" onClick={handleActionClick}>
            {actionText !== '' ? actionText : 'Go'}
          </button>
        </div>
      ) : null}
    </div>
  );
};

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onActionClick: PropTypes.func,
  actionText: PropTypes.string,
};

EmptyState.defaultProps = {
  actionText: '',
};

export default EmptyState;
