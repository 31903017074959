import React, { useEffect, useState, useCallback, useRef } from 'react';
import './ListingEditPhotos.scss';

import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone';
import { TextField } from '@mui/material';

import responsive from '../../../../core/responsive';
import { Loader, Modal, SlideShow, NoImagePlaceholder } from '../../../../core/components';

import ListingAdminPhotoService from '../ListingAdminPhoto.service';

export const ListingEditPhotos = ({ lang, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [listingId] = useState(props.listingId);
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [slideShowIndex, setSlideShowIndex] = useState(0);
  const [isLarge, setIsLarge] = useState(window.innerWidth > responsive.mdMinWidth);

  const modal = useRef();

  window.onresize = function (event) {
    if (event.srcElement.innerWidth < responsive.mdMinWidth && isLarge) {
      setIsLarge(false);
    }

    if (event.srcElement.innerWidth > responsive.mdMinWidth && !isLarge) {
      setIsLarge(true);
    }
  };

  const databind = useCallback(() => {
    const service = new ListingAdminPhotoService();

    service
      .get(listingId)
      .then((x) => {
        setItems(x.data);
        setLoadingCompleted(true);
      })
      .catch((e) => service.handleHttpError(e, enqueueSnackbar));
  }, [listingId]);

  useEffect(() => {
    databind();
  }, [databind]);

  const upload = (files) => {
    if (files.length === 0) return;

    setLoadingCompleted(false);

    const data = new FormData();
    for (var file of files) {
      data.append('files', file);
    }

    const service = new ListingAdminPhotoService();
    service
      .upload(listingId, data)
      .then((x) => {
        databind();
        enqueueSnackbar(lang.profilePhotoUpdated, {
          variant: 'success',
        });
      })
      .catch((e) => service.handleHttpError(e, enqueueSnackbar));
  };

  const deleteImage = (id, e) => {
    e.stopPropagation();

    const service = new ListingAdminPhotoService();
    service
      .delete(listingId, id)
      .then((x) => {
        databind();
      })
      .catch(service.handleHttpError);
  };

  const updateName = (id, e) => {
    e.stopPropagation();

    const data = {
      id: id,
      name: e.target.value,
    };

    const service = new ListingAdminPhotoService();
    service
      .update(listingId, data)
      .then((x) => {
        databind();
      })
      .catch(service.handleHttpError);
  };

  const onImageClicked = (index) => {
    if (index < 0) throw Error('Invalid image slide show index.');

    console.log(index);

    setSlideShowIndex(index);
    modal.current.toggleModal(true);

    setShowModal(true);
    modal.current.toggleModal(true);
  };

  const images = items.map((x, index) => (
    <div key={x.id} className="image-item box1">
      <div>
        <img src={`${x.image}&s=250`} alt="listing" onClick={() => onImageClicked(index)} />
        <footer>
          <TextField
            label={lang.imageName}
            onBlur={(e) => updateName(x.id, e)}
            defaultValue={x.name === 'listing-image' ? null : x.name}
            fullWidth
          />
          <button className="cx-button cx-button-delete" onClick={(e) => deleteImage(x.id, e)} title={lang.delete}>
            <i className="icon-trash-2" />
          </button>
        </footer>
      </div>
    </div>
  ));

  return (
    <>
      <div className="cx-admin-listing-edit-photos">
        <div className="upload-actions">
          <Dropzone onDrop={upload}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {images.length === 0 ? <NoImagePlaceholder multi={true} /> : null}
                  <p>{lang.dropListingPhotos}</p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        {!loadingCompleted ? (
          <div className="loader">
            <Loader />
            <p>{lang.loadingImages}</p>
          </div>
        ) : images.length > 0 ? (
          <section className="cx-admin-listing-edit-photos-list">
            <header>
              <h2>{`${lang.imageTotal}${images.length}`}</h2>
            </header>
            <div className="list">{images}</div>
          </section>
        ) : null}
      </div>

      <Modal ref={modal} className="cx-listing-modal">
        {showModal ? <SlideShow index={slideShowIndex} images={items} className="cx-listing-slide-show" /> : null}
      </Modal>
    </>
  );
};

export default withRouter(ListingEditPhotos);
