import React from 'react';
//import { ReactComponent as Logo } from '../../../../assets/img/Listatec-logo.svg';
import logo from '../../../../assets/img/Dakity-logo.png';
import { Link } from 'react-router-dom';

export const SideNavigationHeader = React.memo(({ lang }) => {
  return (
    <header>
      <Link to="/">
        <img src={logo} alt="Logo" />
        <label>akity</label>
      </Link>
    </header>
  );
});
