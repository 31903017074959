export const SETRESOLUTIONWIDTH = 'SETRESOLUTIONWIDTH';
export const SETRESOLUTIONHEIGHT = 'SETRESOLUTIONHEIGHT';

export const setWidth = (width) => ({
  type: SETRESOLUTIONWIDTH,
  data: width,
});

export const setHeight = (height) => ({
  type: SETRESOLUTIONHEIGHT,
  data: height,
});
