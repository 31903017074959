export class WineStyleSearchDto {
  text = '';
}

export class WineStyleSearchRequest {
  limit = 1000;
  skip = 0;
  count = 0;
  search = new WineStyleSearchDto();
}
