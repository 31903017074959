export class PasswordSearchDto {
  text = null;
  sort = 'name';
  sortDirection = 'asc';
}

export class PasswordSearchRequest {
  limit = 30;
  skip = 0;
  count = 0;
  search = new PasswordSearchDto();
}
