export const CHANGELANGUAGE = 'CHANGELANGUAGE';
export const SETWORKSPACE = 'SETWORKSPACE';
export const SETCONTENTGROUP = 'SETCONTENTGROUP';

export const changeLanguage = (newLanguage) => ({
  type: CHANGELANGUAGE,
  data: newLanguage,
});
export const setWorkspace = (workspace) => ({
  type: SETWORKSPACE,
  data: workspace,
});
export const setContentGroup = (contentGroup) => ({
  type: SETCONTENTGROUP,
  data: contentGroup,
});
