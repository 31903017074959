import React, { Component } from 'react';
import './MapView.scss';
import PropTypes from 'prop-types';

let google = null;

class MapView extends Component {
  constructor({ items }) {
    super();
    this.state = { items };

    this.removeGoogleMapScript();
    // Start Google Maps API loading since we know we'll soon need it
    this.getGoogleMaps();
  }

  baseUrl = window.location.protocol + '//' + window.location.host;

  componentDidMount() {
    // Once the Google Maps API has finished loading, initialize the map
    // 18.450793, -66.073498
    this.getGoogleMaps().then((google) => {
      let latitude = 18.450793;
      let longitude = -66.223498;
      let item = null;

      if (this.state.items.length === 1) {
        item = this.state.items[0];
        latitude = item.latitude;
        longitude = item.longitude;
      }

      const map = new window.google.maps.Map(document.getElementById('map-detail'), {
        zoom: 15,
        center: { lat: latitude, lng: longitude },
      });

      this.state.items.map((x) => this.addMarker(map, x.latitude, x.longitude, x.id));
    });
  }

  getGoogleMaps() {
    // If we haven't already defined the promise, define it
    if (!this.googleMapsPromise) {
      this.googleMapsPromise = new Promise((resolve) => {
        // Add a global handler for when the API finishes loading
        window.resolveGoogleMapsPromise = () => {
          // Resolve the promise
          resolve(google);

          // Tidy up
          delete window.resolveGoogleMapsPromise;
        };

        // Load the Google Maps API
        const script = document.createElement('script');
        const API = 'AIzaSyBptjTXZo7icAxPGdjq09JyNq-6wPEVYrg';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&callback=resolveGoogleMapsPromise`;
        script.async = true;
        document.body.appendChild(script);
      });
    }

    // Return a promise for the Google Maps API
    return this.googleMapsPromise;
  }

  removeGoogleMapScript = () => {
    // console.debug('removing google script...');
    let keywords = ['maps.googleapis'];

    //Remove google from BOM (window object)
    window.google = undefined;

    //Remove google map scripts from DOM
    let scripts = document.head.getElementsByTagName('script');
    for (let i = scripts.length - 1; i >= 0; i--) {
      let scriptSource = scripts[i].getAttribute('src');
      if (scriptSource != null) {
        if (keywords.filter((item) => scriptSource.includes(item)).length) {
          scripts[i].remove();
        }
      }
    }
  };

  options = {
    center: { lat: 36.890257, lng: 30.707417 },
    zoom: 12,
  };

  addMarker = (map: window.google.maps.Map, lat, long, id: string) => {
    const marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(lat, long),
      map: map,
      animation: window.google.maps.Animation.Drop,
      url: `${this.baseUrl}/listings/${id}`,
    });

    window.google.maps.event.addDomListener(marker, 'click', function () {
      window.location.href = marker.url;
    });
  };

  render() {
    return <div id="map-detail" style={{ width: '100%', height: '290px' }} />;
  }
}

MapView.propTypes = {
  items: PropTypes.array.isRequired,
};
MapView.defaultProps = {};

export default MapView;
