export class BeerStyleSearchDto {
  text = '';
}

export class BeerStyleSearchRequest {
  limit = 1000;
  skip = 0;
  count = 0;
  search = new BeerStyleSearchDto();
}
