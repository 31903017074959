import React from 'react';
import './actionMenu.scss';
import UserAction from './UserAction';

import { withRouter } from 'react-router-dom';

const ActionMenu = () => {
  return (
    <div className="cx-header-actions">
      <div className="cx-header-actions-content">
        <UserAction />
      </div>
    </div>
  );
};

export default withRouter(ActionMenu);
