import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const Money = ({ number, symbol }) => {
  return <NumberFormat value={number} displayType={'text'} thousandSeparator={true} prefix={symbol} />;
};

Money.propTypes = {
  number: PropTypes.number.isRequired,
  symbol: PropTypes.string.isRequired,
};

export default Money;
