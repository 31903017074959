import React from 'react';
import './Card.scss';
import PropTypes from 'prop-types';

const Card = ({ className, children, onClick }) => {
  return (
    <div className={className == null ? 'cx-card' : `cx-card ${className}`} onClick={onClick == null ? null : onClick}>
      <div className="cx-card-container">{children}</div>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};
Card.defaultProps = {};

export default Card;
