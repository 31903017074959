import React from 'react';
import './NoteItem.scss';

const NoteItem = ({ lang, item, selectedItem, onItemSelected }) => {
  const getModifyDate = (date) => {
    return new Date(date).toLocaleString();
  };
  const titleMaxLength = 40;
  const trimTitle = (title) => {
    if (title.length > titleMaxLength) {
      return `${title.substring(0, titleMaxLength)} ...`;
    }
    return title;
  };

  return (
    <div
      className={selectedItem.id === item.id ? 'cx-note-item cx-note-item-selected' : 'cx-note-item'}
      onClick={() => onItemSelected(item)}
    >
      <p className="cx-note-item-title">{trimTitle(item.title)}</p>
      <small>{`${lang.lastUpdated} ${getModifyDate(item.modifyDate)}`}</small>
    </div>
  );
};

export default NoteItem;
