import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

const CxConfirm = ({
  lang,
  type,
  message,
  show,
  confirmButtonText,
  cancelButtonText,
  onConfirmClick,
  onCancelClick,
}) => {
  const [openDialog, toggleOpenDialog] = useState(false);

  useEffect(() => {
    if (show) {
      toggleOpenDialog(true);
    }
  }, [show]);

  const handleCancelClick = () => {
    toggleOpenDialog(false);
    if (onCancelClick) onCancelClick();
  };

  const handleConfirmClick = () => {
    toggleOpenDialog(false);
    onConfirmClick();
  };

  const getButtonType = () => {
    if (type === 'delete') {
      return 'cx-button cx-button-delete';
    }
    return 'cx-button cx-button-action';
  };

  return (
    <Dialog open={openDialog} onClose={handleCancelClick} aria-labelledby="beer-delete-dialog-title">
      <DialogTitle id="beer-delete-dialog-title">{message}</DialogTitle>
      <DialogActions>
        <button type="button" className="cx-button cx-button-cancel" onClick={handleCancelClick}>
          {cancelButtonText ? cancelButtonText : lang.cancel}
        </button>
        <button type="button" className={getButtonType()} onClick={handleConfirmClick}>
          {confirmButtonText ? confirmButtonText : lang.ok}
        </button>
      </DialogActions>
    </Dialog>
  );
};

CxConfirm.propTypes = {
  lang: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  onCancelClick: PropTypes.func,
};

CxConfirm.defaultProps = {};

export default CxConfirm;
