import React from 'react';
import PropTypes from 'prop-types';

const BrowserWindowsReachBottomScroll = ({ padding, action, children }) => {
  window.onscroll = function (ev) {
    const currentWindowHeight = window.innerHeight ? window.innerHeight : document.body.clientHeight; // gets window height

    // gets current vertical scrollbar position
    let currentScrollPosition = window.pageYOffset
      ? window.pageYOffset
      : document.documentElement.scrollTop
      ? document.documentElement.scrollTop
      : document.body.scrollTop;

    // if scrollbar reaches to bottom
    if (document.body.scrollHeight - padding <= currentScrollPosition + currentWindowHeight) {
      console.log('reach bottom');
      action();
    }
  };
  return <></>;
};

BrowserWindowsReachBottomScroll.propTypes = {
  padding: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
  children: PropTypes.object,
};

export default BrowserWindowsReachBottomScroll;
