import React, { useState, useRef } from 'react';
import './NoteList.scss';

import NoteItem from './NoteItem';
import { NoteSearchRequest } from './NoteSearch.dto';

// Mui
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

const NoteList = ({ lang, items, selectedItem, onNoteSelected, onSearch, onSort }) => {
  const onItemSelected = (item) => {
    onNoteSelected(item);
  };

  // Note Search
  const searchRef = useRef();
  const [isSearching, setIsSearching] = useState(false);
  const [searchRequest, setSearchRequest] = useState(new NoteSearchRequest());

  const clearSearchClick = () => {
    searchRef.current.value = null;
    const search = { ...searchRequest };
    search.search.text = null;
    setSearchRequest(search);
    onSearch(search);
    setIsSearching(false);
  };

  const onSearchInputChange = (event) => {
    const search = { ...searchRequest };
    search.search.text = event.target.value;
    setSearchRequest(search);

    if (!search.search.text) {
      clearSearchClick();
      return;
    }

    onSearch(search);
    setIsSearching(true);
  };

  // Note Sorting
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSortClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSortClick = (sortField) => {
    const request = { ...searchRequest };
    const { sort } = request.search;

    if (sortField === sort) {
      request.search.sortDirection = request.search.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      request.search.sort = sortField;
      request.search.sortDirection = 'asc';
    }

    onSort(request);
    setSearchRequest(request);
    setAnchorEl(null);
  };

  const list = items.map((x) => (
    <li key={x.id}>
      <NoteItem lang={lang} item={x} selectedItem={selectedItem ?? 0} onItemSelected={onItemSelected} />
    </li>
  ));
  return (
    <>
      <div className="cx-note-search">
        <Paper component="form" sx={{ p: '0px 8px', display: 'flex', alignItems: 'center' }}>
          <InputBase
            type="text"
            inputRef={searchRef}
            placeholder={lang.search}
            onChange={onSearchInputChange}
            sx={{ ml: 1, flex: 1 }}
            inputProps={{ 'aria-label': lang.search }}
          />
          {isSearching ? (
            <IconButton type="submit" aria-label="clear-search" onClick={clearSearchClick} size="large">
              <i className="icon-times" />
            </IconButton>
          ) : null}
          <Divider orientation="vertical" />
          <IconButton type="button" aria-label="sort" onClick={handleSortClick} size="large">
            <i className="icon-sort-amount-asc" />
          </IconButton>
        </Paper>
        <small>Total Notes: {items.length}</small>
      </div>

      <div className="cx-note-list">
        <ul>{list}</ul>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="cx-beer-search-sort">
          <h3>Sort By</h3>
          <ul>
            <li onClick={() => onSortClick('title')}>
              {lang.title}
              {searchRequest.search.sort === 'title' ? (
                searchRequest.search.sortDirection === 'asc' ? (
                  <i className="icon-sort-amount-asc" />
                ) : (
                  <i className="icon-sort-amount-desc" />
                )
              ) : null}
            </li>
            <li onClick={() => onSortClick('modifyDate')}>
              {lang.uLastUpdated}
              {searchRequest.search.sort === 'modifyDate' ? (
                searchRequest.search.sortDirection === 'asc' ? (
                  <i className="icon-sort-amount-asc" />
                ) : (
                  <i className="icon-sort-amount-desc" />
                )
              ) : null}
            </li>
          </ul>
        </div>
      </Popover>
    </>
  );
};

export default NoteList;
