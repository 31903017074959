import React, { useEffect, useState } from 'react';
import './Diagnostics.scss';

import useApi from '../../../core/useApi';

const Diagnostics = () => {
  const { get } = useApi();
  const [status, setStatus] = useState();

  const getSystemStatus = async () => {
    const response = await get('diagnostics/environment-name');
    setStatus(response);
    console.log(response);
  };

  useEffect(() => {
    getSystemStatus();
  }, []);

  return (
    <div className="cx-diagnostics">
      <h2>System Status</h2>
      {status ? (
        <>
          <ul>
            <li>
              Environment: <span>{status.environment}</span>
            </li>
            <li>
              Audience: <span>{status.identityServer.audience}</span>
            </li>
            <li>
              Backend API endpoint: <span>{status.identityServer.authority}</span>
            </li>
          </ul>
        </>
      ) : null}
    </div>
  );
};

export default Diagnostics;
