import React, { useState } from 'react';
import './AccountRegister.scss';

import useApi from '../../../../core/useApi';
import { useHistory } from 'react-router-dom';

// State
import { useSelector } from 'react-redux';

// Forms
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// UI
import { Loader, Panel, CxSelect } from '../../../../core/components';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

const AccountRegister = () => {
  const lang = useSelector((state) => state.app.lang);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { post } = useApi();

  // Form
  const schema = yup
    .object({
      email: yup.string().email().required(lang.validationFieldRequired),
      name: yup.string().required(lang.validationFieldRequired).min(2),
      lastName: yup.string().required(lang.validationFieldRequired).min(2),
      phone: yup.string().required(lang.validationFieldRequired),
      cultureCode: yup.string(),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      name: '',
      middleName: '',
      lastName: '',
      phone: '',
      cultureCode: '',
    },
  });

  const create = async (data) => {
    setIsLoading(true);
    try {
      await post(`account/register`, data);
      enqueueSnackbar(lang.accountCreated, {
        variant: 'success',
      });
      history.push(`/account/activation?email=${data.email}`);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = (data) => {
    create(data);
  };

  const cancelClick = () => {
    history.push({
      pathname: '/login',
    });
  };

  // Culture Code Select
  const [selectedCultureCode, setSelectedCultureCode] = useState('es-PR');
  const cultureCodeList = [
    { name: 'English', value: 'en-US' },
    { name: 'Español', value: 'es-PR' },
  ];
  const handleCultureCodeChange = (value) => {
    setSelectedCultureCode(value);
    setValue('cultureCode', value, true);
  };

  return (
    <div className="cx-account-register">
      <h2>{lang.createAccount}</h2>
      <Panel title={lang.Registration}>
        {isLoading ? (
          <>
            <Loader />
            <p>{lang.creatingAccount}</p>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="email"
                    type="email"
                    label={lang.email}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="name"
                    label={lang.name}
                    variant="outlined"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message : ''}
                  />
                )}
                rules={{ required: true, maxLength: 50 }}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="middleName"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="middleName"
                    label={lang.middleName}
                    variant="outlined"
                    fullWidth
                    error={!!errors.middleName}
                    helperText={errors.middleName ? errors.middleName.message : ''}
                  />
                )}
                rules={{ maxLength: 50 }}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="lastName"
                    label={lang.lastName}
                    variant="outlined"
                    fullWidth
                    error={!!errors.lastName}
                    helperText={errors.lastName ? errors.lastName.message : ''}
                  />
                )}
                rules={{ required: true, maxLength: 50 }}
              />
            </div>

            <div>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="phone"
                    type="tel"
                    label={lang.phone}
                    variant="outlined"
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone ? errors.phone.message : ''}
                  />
                )}
                rules={{ required: true, minLength: 6, maxLength: 12 }}
              />
            </div>

            <div>
              <CxSelect
                name="cultureCode"
                label={lang.language}
                fullWidth
                items={cultureCodeList}
                defaultValue={selectedCultureCode}
                onChange={handleCultureCodeChange}
              />

              {errors.cultureCode && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div className="cx-form-buttons">
              <button type="button" className="cx-button cx-button-cancel" onClick={cancelClick}>
                {lang.cancel}
              </button>
              <input type="submit" className="cx-button cx-button-save" value={lang.createAccount} />
            </div>
          </form>
        )}
      </Panel>
    </div>
  );
};

export default AccountRegister;
