import ServiceBase from '../../../core/services/serviceBase';

export default class GrapeService extends ServiceBase {
  search = (request) => super.http.get(`grapes?${this._parseQuery(request)}`);
  get = (id) => super.http.get(`grapes/${id}`);
  create = (data) => super.http.post('grapes', data);

  _parseQuery = ({ limit, skip, search }) => {
    let query = '';

    query += `&searchText=${search.text}`;
    query += `&limit=${limit}&skip=${skip}`;
    query += '&sort=name.asc';

    //  console.log(query);

    return query;
  };
}
