export class BrewerySearchDto {
  text = '';
}

export class BrewerySearchRequest {
  limit = 1000;
  skip = 0;
  count = 0;
  search = new BrewerySearchDto();
}
