import React, { useEffect } from 'react';

// State
import { logout } from '../redux/actions';
import { useDispatch } from 'react-redux';

// UI
import { Loader } from '../../../../core/components';

const Logout = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('logging out');
    dispatch(logout());
    history.push({
      pathname: '/login',
    });
  }, []);

  return <Loader />;
};

export default Logout;
