import React from 'react';
import './accountSettings.scss';

import { withRouter } from 'react-router-dom';

// State
import { useSelector } from 'react-redux';
import { Breadcrumb, Tabs } from '../../../../core/components';

import AccountProfile from '../profile/profile';
import AccountApps from '../apps/accountApps';

const AccountSettings = () => {
  const lang = useSelector((state) => state.app.lang);

  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.accountSettings, path: null },
  ];

  const tabs = [
    {
      key: 'PROFILE',
      name: lang.profile,
      icon: 'icon-user',
      content: <AccountProfile lang={lang} />,
      isDefault: true,
    },
    {
      key: 'APPS',
      name: lang.tools,
      icon: 'icon-tools',
      content: <AccountApps lang={lang} />,
      isDefault: false,
    },
  ];

  return (
    <div className="cx-account-settings">
      <Breadcrumb items={breadcrumb} />
      <Tabs items={tabs} />
    </div>
  );
};

export default withRouter(AccountSettings);
