import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';

import AppContainer from './components/AppContainer';

const useStrictMode = true;

const root = ReactDOM.createRoot(document.getElementById('root'));

if (useStrictMode) {
  root.render(<AppContainer />);
} else {
  root.render(<AppContainer />);
}
