import React from 'react';
import './ListingDashboard.scss';

import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Breadcrumb, Panel } from '../../../core/components';
import ListingSearch from './search/ListingSearch';

const ListingDashboard = ({ history }) => {
  const lang = useSelector((state) => state.app.lang);
  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.listingDashboard, path: null },
  ];

  const navigateTo = (path) => {
    history.push(path);
  };
  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <div className="cx-listing-dashboard">
        <div className="grid21">
          <section className="grid21-item">
            <Panel title={lang.search} className="listing-search">
              <ListingSearch lang={lang}></ListingSearch>
            </Panel>
          </section>
          <section className="grid21-item">
            <Panel title={lang.quickActions} className="quick-actions">
              <ul>
                <li onClick={(x) => navigateTo('/listings/new')}>{lang.addListing}</li>
              </ul>
            </Panel>
            <Panel title={lang.reports}></Panel>
            <Panel title={lang.trendingListings}></Panel>
          </section>
        </div>
      </div>
    </>
  );
};

export default withRouter(ListingDashboard);
