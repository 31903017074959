import React, { useEffect, useState } from 'react';
import './AccountEmail.scss';

import { useHistory } from 'react-router-dom';

import useApi from '../../../../core/useApi';

// State
import { useSelector } from 'react-redux';
import queryString from 'query-string';

// Forms
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// UI
import { Loader, Panel } from '../../../../core/components';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

const AccountEmail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const lang = useSelector((state) => state.app.lang);
  const [isLoading, setIsLoading] = useState(false);

  const { get, post } = useApi();

  // Form
  const [targetEmail, setTargetEmail] = useState();
  const schema = yup
    .object({
      email: yup.string().email().required(lang.validationFieldRequired),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const create = async (data) => {
    setIsLoading(true);
    try {
      const response = await post(`account/email/validation`, data);
      setTargetEmail(response.email);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = (data) => {
    create(data);
  };

  const continueClick = () => {
    history.push(`/account/password/recover?email=${targetEmail}`);
  };

  const cancelClick = () => {
    history.push({
      pathname: '/login',
    });
  };

  return (
    <div className="cx-account-email-validation">
      <h2>{lang.youForgotPasswordTitle}</h2>
      <Panel title={lang.emailValidation}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {targetEmail ? (
              <div>
                <p>{lang.recoverPasswordEmailInstructions}</p>
                <button type="button" className="cx-button cx-button-save" onClick={continueClick}>
                  {lang.continue}
                </button>
              </div>
            ) : (
              <>
                <p>{lang.youForgotPasswordInstructions}</p>
                <form id="account-validation" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <div>
                    <Controller
                      control={control}
                      name="email"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="email"
                          type="email"
                          label={lang.email}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          error={!!errors.email}
                          helperText={errors.email ? errors.email.message : ''}
                        />
                      )}
                    />
                  </div>

                  <div className="cx-form-buttons">
                    <button type="button" className="cx-button cx-button-cancel" onClick={cancelClick}>
                      {lang.cancel}
                    </button>

                    <input type="submit" className="cx-button cx-button-save" value={lang.requestPasswordReset} />
                  </div>
                </form>
              </>
            )}
          </>
        )}
      </Panel>
    </div>
  );
};

export default AccountEmail;
