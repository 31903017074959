export class NoteSearchDto {
  text = null;
  sort = 'name';
  sortDirection = 'asc';
}

export class NoteSearchRequest {
  limit = 1000;
  skip = 0;
  count = 0;
  search = new NoteSearchDto();
}
