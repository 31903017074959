import React, { useState } from 'react';
import './Tree.scss';
import PropTypes from 'prop-types';
import TreeItem from './TreeItem';

const Tree = ({ id, lang, data, selectedItem, onItemClick, preIcon }) => {
  const [selectedId, setSelectedId] = useState(selectedItem ? selectedItem.id : null);

  const handleItemClick = (item) => {
    setSelectedId(item.id);
    onItemClick(item);
  };

  const buildTree = (item) => (
    <TreeItem
      key={item.id}
      buildTree={buildTree}
      item={item}
      onItemClick={handleItemClick}
      preIcon={preIcon}
      lang={lang}
      selectedId={selectedId}
    />
  );

  return (
    <ul id={id} className="cx-tree">
      {data.map((item) => buildTree(item))}
    </ul>
  );
};

Tree.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isDefault: PropTypes.bool.isRequired,
      children: PropTypes.array,
      sharedWithMe: PropTypes.bool,
      sharedWithOthers: PropTypes.bool,
    })
  ),
  onItemClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.object.isRequired,
  preIcon: PropTypes.object,
  lang: PropTypes.object.isRequired,
};

Tree.defaultProps = {};

export default Tree;
