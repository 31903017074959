import React, { useState } from 'react';
import './ListingEdit.scss';

import { connect } from 'react-redux';

import { Breadcrumb, Tabs } from '../../../../core/components';
import ListingEditForm from './ListingEditForm';
import ListingEditPhotos from './ListingEditPhotos';
import PointsOfInterest from '../pointsOfInterest/poi';

const ListingEdit = ({ lang, match }) => {
  const emptyGuid = '00000000-0000-0000-0000-000000000000';
  const [listingId, setListingId] = useState(match.params.id);

  const onListingIdChange = (newId) => {
    setListingId(newId);
  };

  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.listingDashboard, path: '/listings' },
    { name: lang.edit, path: null },
  ];

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <div className="cx-admin-listing-edit">
        <Tabs
          items={
            listingId === 'new'
              ? [
                  {
                    key: 'LISTING',
                    name: lang.listingProfile,
                    icon: 'icon-building',
                    content: (
                      <ListingEditForm
                        lang={lang}
                        listingId={listingId}
                        emptyGuid={emptyGuid}
                        onListingIdChange={onListingIdChange}
                      />
                    ),
                    isDefault: true,
                  },
                ]
              : [
                  {
                    key: 'LISTING',
                    name: lang.listingProfile,
                    icon: 'icon-building',
                    content: (
                      <ListingEditForm
                        lang={lang}
                        listingId={listingId}
                        emptyGuid={emptyGuid}
                        onListingIdChange={onListingIdChange}
                      />
                    ),
                    isDefault: true,
                  },
                  {
                    key: 'PHOTOS',
                    name: lang.photos,
                    icon: 'icon-picture-streamline-1',
                    content: <ListingEditPhotos lang={lang} listingId={listingId} />,
                    isDefault: false,
                  },
                  {
                    key: 'POI',
                    name: lang.poi,
                    icon: 'icon-globe',
                    content: <PointsOfInterest lang={lang} listingId={listingId} emptyGuid={emptyGuid} />,
                    isDefault: false,
                  },
                ]
          }
        />
      </div>
    </>
  );
};

const mapStateToProps = (state, props = {}) => {
  return {
    lang: state.app.lang,
  };
};

export default connect(mapStateToProps, null)(ListingEdit);
