import React from 'react';
import PropTypes from 'prop-types';

const Slide = ({ image, page, total }) => {
  return (
    <>
      <div className={`mySlides fade slide-${image.id}`}>
        <div className="numbertext">
          {page} / {total}
        </div>
        <img src={image.imageLarge} style={{ width: '100%' }} alt={image.name} />
        <div className="text">{image.name}</div>
      </div>
    </>
  );
};

Slide.propTypes = {
  image: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default Slide;
