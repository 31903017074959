import React, { useEffect, useState } from 'react';
import './BeerEdit.scss';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import Dropzone from 'react-dropzone';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { Breadcrumb, Loader, NoImagePlaceholder, CxSlider } from '../../../core/components';
import BeerService from './beer.service';
import BreweryService from './brewery.service';
import { BeerStyleSearchRequest } from './BeerStyleSearch.dto';
import BeerStyleService from './beerStyle.service';
import { BrewerySearchRequest } from './BrewerySearch.dto';

const filter = createFilterOptions();

const BeerEdit = ({ history, match }) => {
  const lang = useSelector((state) => state.app.lang);
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loadingCompleted, setLoadingCompleted] = useState(0);
  const [beerId] = useState(match.params.id);

  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.beers, path: '/beers' },
    { name: lang.edit, path: null },
  ];

  // Brewery
  const [breweries, setBreweries] = useState([]);
  const [brewery, setBrewery] = React.useState(null);
  const [openBreweryDialog, toggleOpenBreweryDialog] = React.useState(false);

  const handleBreweryDialogClose = () => {
    setBreweryDialogValue({
      name: '',
      id: null,
    });

    toggleOpenBreweryDialog(false);
  };

  const [breweryDialogValue, setBreweryDialogValue] = React.useState({
    name: '',
    id: '',
  });

  const handleBrewerySubmit = (event) => {
    event.preventDefault();
    console.log(breweryDialogValue);

    const newBrewery = {
      name: breweryDialogValue.name,
    };

    const service = new BreweryService();
    service
      .create(newBrewery)
      .then((x) => {
        setBrewery(x.data);
        handleBreweryDialogClose();
      })
      .catch((er) => service.handleHttpError(er, enqueueSnackbar));
  };

  // Beer Style
  const [styles, setStyles] = useState([]);
  const [style, setStyle] = React.useState(null);
  const [openStyleDialog, toggleOpenStyleDialog] = React.useState(false);

  const handleStyleDialogClose = () => {
    setStyleDialogValue({
      name: '',
      id: null,
    });

    toggleOpenStyleDialog(false);
  };

  const [styleDialogValue, setStyleDialogValue] = React.useState({
    name: '',
    id: '',
  });

  const handleStyleSubmit = (event) => {
    event.preventDefault();
    console.log(styleDialogValue);

    const newStyle = {
      name: styleDialogValue.name,
    };

    const service = new BeerStyleService();
    service
      .create(newStyle)
      .then((x) => {
        setStyle(x.data);
        handleStyleDialogClose();
      })
      .catch((er) => service.handleHttpError(er, enqueueSnackbar));
  };

  // Rating
  const [rating, setRating] = useState(1);
  const handleBeerRatingValueChange = (value) => {
    console.log(value);
    setRating(value);
  };
  const getSliderValueText = (value) => {
    return `${value}`;
  };

  useEffect(() => {
    const request = new BrewerySearchRequest();
    const service = new BreweryService();
    service
      .search(request)
      .then((x) => {
        setBreweries(x.data);
      })
      .catch((er) => service.handleHttpError(er, enqueueSnackbar));

    const styleRequest = new BeerStyleSearchRequest();
    const styleService = new BeerStyleService();
    styleService
      .search(styleRequest)
      .then((x) => {
        setStyles(x.data);
      })
      .catch((er) => styleService.handleHttpError(er, enqueueSnackbar));
  }, []);

  useEffect(() => {
    //const id = match.params.id;
    // const request = { ...searchRequest };
    if (breweries && styles) {
      if (beerId !== 'new') {
        const service = new BeerService();
        service
          .get(beerId)
          .then((x) => {
            reset({
              id: x.data.id,
              name: x.data.name,
              drinkPlace: x.data.drinkPlace,
              alcoholPerVolume: x.data.alcoholPerVolume,
              tastingNote: x.data.tastingNote,
            });
            setImg(x.data.imageUrl);
            setRating(x.data.rating);
            if (x.data.breweryId) {
              setBrewery({ id: x.data.breweryId, name: x.data.brewery });
            }
            if (x.data.styleId) {
              setStyle({ id: x.data.styleId, name: x.data.style });
            }
            setLoadingCompleted(loadingCompleted + 1);
          })
          .catch((er) => service.handleHttpError(er, enqueueSnackbar));
      } else {
        setLoadingCompleted(loadingCompleted + 1);
        reset({
          id: '',
          name: '',
          drinkPlace: '',
          alcoholPerVolume: '',
          tastingNote: '',
        });
      }
    }
  }, [breweries, styles, beerId]);

  const onCancelClick = () => {
    history.push('/beers');
  };

  // Delete Beer
  const [openDeleteDialog, toggleOpenDeleteDialog] = React.useState(false);

  const handleDeleteDialogClose = () => {
    toggleOpenDeleteDialog(false);
  };

  const onDeleteConfirmClick = () => {
    toggleOpenDeleteDialog(true);
  };
  const onDeleteClick = () => {
    const service = new BeerService();
    service
      .delete(beerId)
      .then((x) => {
        toggleOpenDeleteDialog(false);
        enqueueSnackbar('Beer deleted!', {
          variant: 'success',
        });
        history.push('/beers');
      })
      .catch((er) => service.handleHttpError(er, enqueueSnackbar));
  };

  // Save
  const onSaveClick = (data) => {
    console.log(data);
    const entity = new FormData();
    if (tempImgData) {
      entity.append('files', tempImgData);
    }
    entity.append('name', data.name);
    entity.append('drinkPlace', data.drinkPlace);
    entity.append('alcoholPerVolume', data.alcoholPerVolume);
    entity.append('tastingNote', data.tastingNote);
    entity.append('rating', rating);
    if (brewery && brewery.id) entity.append('breweryId', brewery.id);
    if (style && style.id) entity.append('styleId', style.id);

    const service = new BeerService();
    const isNew = data.id === '';
    let request = null;
    if (isNew) {
      request = service.create(entity);
    } else {
      request = service.update(data.id, entity);
    }
    request
      .then((x) => {
        if (isNew) {
          enqueueSnackbar(lang.beerAddedToCooler, {
            variant: 'success',
          });
          history.push('/beers');
        } else {
          enqueueSnackbar(lang.beerUpdated, {
            variant: 'success',
          });
        }
      })
      .catch((e) => service.handleHttpError(e, enqueueSnackbar));
  };

  // Image Upload
  const [tempImgData, setImgData] = useState();
  const [tempImg, setTempImg] = useState();
  const [img, setImg] = useState();
  const myUploader = (files) => {
    setImgData(files[0]);

    // Temp Upload
    const data = new FormData();
    data.append('file', files[0]);
    const service = new BeerService();
    service
      .tempImageUpload(data)
      .then((x) => {
        setTempImg(x.data);
      })
      .catch(service.handleHttpError);
  };

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <div className="cx-beers-edit">
        {!loadingCompleted ? (
          <Loader />
        ) : (
          <>
            <div>
              <form className="cx-beers-edit-form" onSubmit={handleSubmit(onSaveClick)}>
                <input type="hidden" {...register('id')} />

                <div className="cx-beers-edit-img">
                  {tempImg ? (
                    <img src={`data:image/jpg;charset=utf-8;base64,${tempImg}`} alt="beer" />
                  ) : img ? (
                    <img src={`${img}&s=450`} alt="beer" />
                  ) : null}
                  <div className="upload-actions">
                    <Dropzone onDrop={myUploader}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps({ multiple: false })}>
                            <input {...getInputProps()} />
                            {img || tempImg ? null : <NoImagePlaceholder multi={false} />}
                            <p>{lang.dropBeerPhoto}</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>

                <div className="cx-beers-edit-fields">
                  <div>
                    <CxSlider
                      min={0.1}
                      max={5}
                      step={0.1}
                      defaultValue={rating}
                      onSliderValueChanged={handleBeerRatingValueChange}
                      valueText={getSliderValueText}
                      labelText={lang.rating}
                    />
                  </div>

                  <div>
                    <TextField
                      id="name"
                      name="name"
                      type="text"
                      label={lang.name}
                      {...register('name', {
                        required: { value: true, message: lang.validationFieldRequired },
                        maxLength: { value: 128, message: 'No more than 128 chars please.' },
                      })}
                      variant="outlined"
                      fullWidth
                    />

                    {errors.name && <span className="input-error">{lang.validationFieldRequired}</span>}
                  </div>

                  <div>
                    <TextField
                      id="alcoholPerVolume"
                      name="alcoholPerVolume"
                      type="text"
                      label={lang.alcoholByVolume}
                      {...register('alcoholPerVolume', { pattern: /^[1-9]\d*(\.\d+)?$/ })}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />

                    {errors.alcoholPerVolume && <span className="input-error">{lang.invalidABVValue}</span>}
                  </div>

                  <div>
                    <Autocomplete
                      value={brewery}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            toggleOpenBreweryDialog(true);
                            setBreweryDialogValue({
                              name: newValue,
                              id: null,
                            });
                          });
                        } else if (newValue && newValue.inputValue) {
                          toggleOpenBreweryDialog(true);
                          setBreweryDialogValue({
                            name: newValue.inputValue,
                            id: null,
                          });
                        } else {
                          setBrewery(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      id="breweries"
                      options={breweries}
                      //getOptionLabel={(option) => (option && option.name ? option.name : '')}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderOption={(props, option) => <li {...props}>{option.name}</li>}
                      //style={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => <TextField {...params} label={lang.brewery} variant="outlined" />}
                    />
                  </div>

                  <div>
                    <Autocomplete
                      value={style}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            toggleOpenStyleDialog(true);
                            setStyleDialogValue({
                              name: newValue,
                              id: null,
                            });
                          });
                        } else if (newValue && newValue.inputValue) {
                          toggleOpenStyleDialog(true);
                          setStyleDialogValue({
                            name: newValue.inputValue,
                            id: null,
                          });
                        } else {
                          setStyle(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      id="styles"
                      options={styles}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderOption={(props, option) => <li {...props}>{option.name}</li>}
                      freeSolo
                      renderInput={(params) => <TextField {...params} label={lang.beerStyle} variant="outlined" />}
                    />
                  </div>

                  <div>
                    <TextField
                      id="tastingNote"
                      name="tastingNote"
                      type="text"
                      label={lang.tastingNote}
                      {...register('tastingNote')}
                      variant="outlined"
                      multiline
                      rows={2}
                      maxRows={3}
                      fullWidth
                    />

                    {errors.tastingNote && <span>{lang.validationFieldRequired}</span>}
                  </div>

                  <div>
                    <TextField
                      id="drinkPlace"
                      name="drinkPlace"
                      type="text"
                      label={lang.drinkPlace}
                      {...register('drinkPlace')}
                      variant="outlined"
                      fullWidth
                    />

                    {errors.drinkPlace && <span className="input-error">{lang.validationFieldRequired}</span>}
                  </div>

                  <div className="cx-admin-footer-actions">
                    {beerId === 'new' ? null : (
                      <button
                        type="button"
                        className="cx-button cx-button-delete"
                        tabIndex="-1"
                        onClick={onDeleteConfirmClick}
                        style={{ float: 'left' }}
                      >
                        {lang.delete}
                      </button>
                    )}
                    <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
                      {lang.cancel}
                    </button>
                    <button type="submit" className="cx-button cx-button-save">
                      {lang.save}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <Dialog open={openBreweryDialog} onClose={handleBreweryDialogClose} aria-labelledby="form-dialog-title">
              <form onSubmit={handleBrewerySubmit}>
                <DialogTitle id="form-dialog-name">{lang.addNewBrewery}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{lang.addNewBreweryContextMessage}</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="brewery-name"
                    value={breweryDialogValue.name}
                    onChange={(event) => setBreweryDialogValue({ ...breweryDialogValue, name: event.target.value })}
                    label="brewery-name"
                    type="text"
                  />
                </DialogContent>
                <DialogActions>
                  <button type="button" className="cx-button cx-button-cancel" onClick={handleBreweryDialogClose}>
                    {lang.cancel}
                  </button>
                  <button type="submit" className="cx-button cx-button-save">
                    {lang.save}
                  </button>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog open={openStyleDialog} onClose={handleStyleDialogClose} aria-labelledby="form-dialog-title">
              <form onSubmit={handleStyleSubmit}>
                <DialogTitle id="form-dialog-name">{lang.addNewBeerStyle}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{lang.addNewBeerStyleContextMessage}</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="beer-style-name"
                    value={styleDialogValue.name}
                    onChange={(event) => setStyleDialogValue({ ...styleDialogValue, name: event.target.value })}
                    label="beer-style-name"
                    type="text"
                  />
                </DialogContent>
                <DialogActions>
                  <button type="button" className="cx-button cx-button-cancel" onClick={handleStyleDialogClose}>
                    {lang.cancel}
                  </button>
                  <button type="submit" className="cx-button cx-button-save">
                    {lang.save}
                  </button>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              open={openDeleteDialog}
              onClose={handleDeleteDialogClose}
              aria-labelledby="beer-delete-dialog-title"
            >
              <DialogTitle id="beer-delete-dialog-title">{lang.deleteBeerConfirmation}</DialogTitle>
              <DialogActions>
                <button type="button" className="cx-button cx-button-cancel" onClick={handleDeleteDialogClose}>
                  {lang.cancel}
                </button>
                <button type="button" className="cx-button cx-button-delete" onClick={onDeleteClick}>
                  {lang.delete}
                </button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(BeerEdit);
