import React, { useEffect, useState } from 'react';
import './WineEdit.scss';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import Dropzone from 'react-dropzone';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { Breadcrumb, Loader, NoImagePlaceholder, CxSlider, CxSelect } from '../../../core/components';
import { helpers } from '../../../core/helpers';
import WineService from './wine.service';
import GrapeService from './grape.service';
import { WineStyleSearchRequest } from './WineStyleSearch.dto';
import WineStyleService from './wineStyle.service';
import { GrapeSearchRequest } from './GrapeSearch.dto';

const filter = createFilterOptions();

const WineEdit = ({ history, match }) => {
  const lang = useSelector((state) => state.app.lang);
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [loadingCompleted, setLoadingCompleted] = useState(0);
  const [wineId] = useState(match.params.id);

  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.wines, path: '/wines' },
    { name: lang.edit, path: null },
  ];

  // Year
  const [selectedYear, setSelectedYear] = useState('');
  const years = helpers.generateArrayOfYears().map((year) => ({ name: year, value: year }));
  const handleYearChange = (value) => {
    setSelectedYear(value);
    setValue('year', value, true);
  };

  // Grape
  const [grapes, setGrapes] = useState([]);
  const [grape, setGrape] = React.useState(null);
  const [openGrapeDialog, toggleOpenGrapeDialog] = React.useState(false);

  const handleGrapeDialogClose = () => {
    setGrapeDialogValue({
      name: '',
      id: null,
    });

    toggleOpenGrapeDialog(false);
  };

  const [grapeDialogValue, setGrapeDialogValue] = React.useState({
    name: '',
    id: '',
  });

  const handleGrapeSubmit = (event) => {
    event.preventDefault();
    console.log(grapeDialogValue);

    const newGrape = {
      name: grapeDialogValue.name,
    };

    const service = new GrapeService();
    service
      .create(newGrape)
      .then((x) => {
        setGrape(x.data);
        handleGrapeDialogClose();
      })
      .catch((er) => service.handleHttpError(er, enqueueSnackbar));
  };

  // Wine Style
  const [styles, setStyles] = useState([]);
  const [style, setStyle] = React.useState(null);
  const [openStyleDialog, toggleOpenStyleDialog] = React.useState(false);

  const handleStyleDialogClose = () => {
    setStyleDialogValue({
      name: '',
      id: null,
    });

    toggleOpenStyleDialog(false);
  };

  const [styleDialogValue, setStyleDialogValue] = React.useState({
    name: '',
    id: '',
  });

  const handleStyleSubmit = (event) => {
    event.preventDefault();
    console.log(styleDialogValue);

    const newStyle = {
      name: styleDialogValue.name,
    };

    const service = new WineStyleService();
    service
      .create(newStyle)
      .then((x) => {
        setStyle(x.data);
        handleStyleDialogClose();
      })
      .catch((er) => service.handleHttpError(er, enqueueSnackbar));
  };

  // Rating
  const [rating, setRating] = useState(1);
  const handleRatingValueChange = (value) => {
    console.log(value);
    setRating(value);
  };
  const getSliderValueText = (value) => {
    return `${value}`;
  };

  useEffect(() => {
    const request = new GrapeSearchRequest();
    const service = new GrapeService();
    service
      .search(request)
      .then((x) => {
        setGrapes(x.data);
      })
      .catch((er) => service.handleHttpError(er, enqueueSnackbar));

    const styleRequest = new WineStyleSearchRequest();
    const styleService = new WineStyleService();
    styleService
      .search(styleRequest)
      .then((x) => {
        setStyles(x.data);
      })
      .catch((er) => styleService.handleHttpError(er, enqueueSnackbar));
  }, []);

  useEffect(() => {
    if (grapes && styles) {
      if (wineId !== 'new') {
        const service = new WineService();
        service
          .get(wineId)
          .then((x) => {
            reset({
              id: x.data.id,
              name: x.data.name,
              price: x.data.price,
              year: x.data.year,
              drinkPlace: x.data.drinkPlace,
              alcoholPerVolume: x.data.alcoholPerVolume,
              tastingNote: x.data.tastingNote,
            });
            setImg(x.data.imageUrl);
            setRating(x.data.rating);
            if (x.data.grapeId) {
              setGrape({ id: x.data.grapeId, name: x.data.grape });
            }
            if (x.data.styleId) {
              setStyle({ id: x.data.styleId, name: x.data.style });
            }
            if (x.data.year) {
              setSelectedYear(x.data.year);
            }

            setLoadingCompleted(loadingCompleted + 1);
          })
          .catch((er) => service.handleHttpError(er, enqueueSnackbar));
      } else {
        setLoadingCompleted(loadingCompleted + 1);
        reset({
          id: '',
          name: '',
          drinkPlace: '',
          alcoholPerVolume: '',
          tastingNote: '',
          price: null,
          year: '',
        });
      }
    }
  }, [grapes, styles, wineId]);

  const onCancelClick = () => {
    history.push('/wines');
  };

  // Delete Wine
  const [openDeleteDialog, toggleOpenDeleteDialog] = React.useState(false);

  const handleDeleteDialogClose = () => {
    toggleOpenDeleteDialog(false);
  };

  const onDeleteConfirmClick = () => {
    toggleOpenDeleteDialog(true);
  };
  const onDeleteClick = () => {
    const service = new WineService();
    service
      .delete(wineId)
      .then((x) => {
        toggleOpenDeleteDialog(false);
        enqueueSnackbar(lang.wineDeleted, {
          variant: 'success',
        });
        history.push('/wines');
      })
      .catch((er) => service.handleHttpError(er, enqueueSnackbar));
  };

  // Save
  const onSaveClick = (data) => {
    console.log(data);
    const entity = new FormData();
    if (tempImgData) {
      entity.append('files', tempImgData);
    }
    entity.append('name', data.name);
    entity.append('price', data.price ?? '');
    entity.append('year', selectedYear);
    entity.append('drinkPlace', data.drinkPlace);
    entity.append('alcoholPerVolume', data.alcoholPerVolume);
    entity.append('tastingNote', data.tastingNote);
    entity.append('rating', rating);
    if (grape && grape.id) entity.append('grapeId', grape.id);
    if (style && style.id) entity.append('styleId', style.id);

    const service = new WineService();
    const isNew = data.id === '';
    let request = null;
    if (isNew) {
      request = service.create(entity);
    } else {
      request = service.update(data.id, entity);
    }
    request
      .then((x) => {
        if (isNew) {
          enqueueSnackbar(lang.wineAddedToCellar, {
            variant: 'success',
          });
          history.push('/wines');
        } else {
          enqueueSnackbar(lang.wineUpdated, {
            variant: 'success',
          });
        }
      })
      .catch((e) => service.handleHttpError(e, enqueueSnackbar));
  };

  // Image Upload
  const [tempImgData, setImgData] = useState();
  const [tempImg, setTempImg] = useState();
  const [img, setImg] = useState();
  const myUploader = (files) => {
    setImgData(files[0]);

    // Temp Upload
    const data = new FormData();
    data.append('file', files[0]);
    const service = new WineService();
    service
      .tempImageUpload(data)
      .then((x) => {
        setTempImg(x.data);
      })
      .catch(service.handleHttpError);
  };

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <div className="cx-wines-edit">
        {!loadingCompleted ? (
          <Loader />
        ) : (
          <>
            <div>
              <form className="cx-wines-edit-form" onSubmit={handleSubmit(onSaveClick)}>
                <input type="hidden" {...register('id')} />

                <div className="cx-wines-edit-img">
                  {tempImg ? (
                    <img src={`data:image/jpg;charset=utf-8;base64,${tempImg}`} alt="wine" />
                  ) : img ? (
                    <img src={`${img}&s=450`} alt="wine" />
                  ) : null}
                  <div className="upload-actions">
                    <Dropzone onDrop={myUploader}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps({ multiple: false })}>
                            <input {...getInputProps()} />
                            {img || tempImg ? null : <NoImagePlaceholder multi={false} />}
                            <p>{lang.dropWinePhoto}</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>

                <div className="cx-wines-edit-fields">
                  <div>
                    <CxSlider
                      min={0.1}
                      max={5}
                      step={0.1}
                      defaultValue={rating}
                      onSliderValueChanged={handleRatingValueChange}
                      valueText={getSliderValueText}
                      labelText={lang.rating}
                    />
                  </div>

                  <div>
                    <TextField
                      id="name"
                      name="name"
                      type="text"
                      label={lang.name}
                      {...register('name', {
                        required: { value: true, message: lang.validationFieldRequired },
                        maxLength: { value: 128, message: 'No more than 128 chars please.' },
                      })}
                      variant="outlined"
                      fullWidth
                    />

                    {errors.name && <span className="input-error">{lang.validationFieldRequired}</span>}
                  </div>

                  <div>
                    {/* <CxSelect
                      id="year"
                      name="year"
                      label={lang.year}
                      defaultValue={selectedYear || ''}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    >
                      {years}
                    </CxSelect> */}

                    <CxSelect
                      name="year"
                      label={lang.year}
                      fullWidth
                      items={years}
                      defaultValue={selectedYear}
                      onChange={handleYearChange}
                    />

                    {errors.year && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
                  </div>

                  <div>
                    <Autocomplete
                      value={style}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            toggleOpenStyleDialog(true);
                            setStyleDialogValue({
                              name: newValue,
                              id: null,
                            });
                          });
                        } else if (newValue && newValue.inputValue) {
                          toggleOpenStyleDialog(true);
                          setStyleDialogValue({
                            name: newValue.inputValue,
                            id: null,
                          });
                        } else {
                          setStyle(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      id="styles"
                      options={styles}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderOption={(props, option) => <li {...props}>{option.name}</li>}
                      freeSolo
                      renderInput={(params) => <TextField {...params} label={lang.style} variant="outlined" />}
                    />
                  </div>

                  <div>
                    <Autocomplete
                      value={grape}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(() => {
                            toggleOpenGrapeDialog(true);
                            setGrapeDialogValue({
                              name: newValue,
                              id: null,
                            });
                          });
                        } else if (newValue && newValue.inputValue) {
                          toggleOpenGrapeDialog(true);
                          setGrapeDialogValue({
                            name: newValue.inputValue,
                            id: null,
                          });
                        } else {
                          setGrape(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      id="grapes"
                      options={grapes}
                      //getOptionLabel={(option) => (option && option.name ? option.name : '')}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderOption={(props, option) => <li {...props}>{option.name}</li>}
                      //style={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => <TextField {...params} label={lang.grape} variant="outlined" />}
                    />
                  </div>

                  <div>
                    <TextField
                      id="price"
                      name="price"
                      type="text"
                      label={lang.price}
                      {...register('price', { pattern: /^[1-9]\d*(\.\d+)?$/ })}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="end">$</InputAdornment>,
                      }}
                    />

                    {errors.price && <span className="input-error">{lang.invalidABVValue}</span>}
                  </div>
                  <div>
                    <TextField
                      id="tastingNote"
                      name="tastingNote"
                      type="text"
                      label={lang.tastingNote}
                      {...register('tastingNote')}
                      variant="outlined"
                      multiline
                      rows={2}
                      maxRows={3}
                      fullWidth
                    />

                    {errors.tastingNote && <span>{lang.validationFieldRequired}</span>}
                  </div>

                  <div>
                    <TextField
                      id="drinkPlace"
                      name="drinkPlace"
                      type="text"
                      label={lang.drinkPlace}
                      {...register('drinkPlace')}
                      variant="outlined"
                      fullWidth
                    />

                    {errors.drinkPlace && <span className="input-error">{lang.validationFieldRequired}</span>}
                  </div>

                  <div>
                    <TextField
                      id="alcoholPerVolume"
                      name="alcoholPerVolume"
                      type="text"
                      label={lang.alcoholByVolume}
                      {...register('alcoholPerVolume', { pattern: /^[1-9]\d*(\.\d+)?$/ })}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />

                    {errors.alcoholPerVolume && <span className="input-error">{lang.invalidABVValue}</span>}
                  </div>

                  <div className="cx-admin-footer-actions">
                    {wineId === 'new' ? null : (
                      <button
                        type="button"
                        className="cx-button cx-button-delete"
                        tabIndex="-1"
                        onClick={onDeleteConfirmClick}
                        style={{ float: 'left' }}
                      >
                        {lang.delete}
                      </button>
                    )}
                    <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
                      {lang.cancel}
                    </button>
                    <button type="submit" className="cx-button cx-button-save">
                      {lang.save}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <Dialog open={openGrapeDialog} onClose={handleGrapeDialogClose} aria-labelledby="form-dialog-title">
              <form onSubmit={handleGrapeSubmit}>
                <DialogTitle id="form-dialog-name">{lang.addNewGrape}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{lang.addNewGrapeContextMessage}</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="grape-name"
                    value={grapeDialogValue.name}
                    onChange={(event) => setGrapeDialogValue({ ...grapeDialogValue, name: event.target.value })}
                    label="grape-name"
                    type="text"
                  />
                </DialogContent>
                <DialogActions>
                  <button type="button" className="cx-button cx-button-cancel" onClick={handleGrapeDialogClose}>
                    {lang.cancel}
                  </button>
                  <button type="submit" className="cx-button cx-button-save">
                    {lang.save}
                  </button>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog open={openStyleDialog} onClose={handleStyleDialogClose} aria-labelledby="form-dialog-title">
              <form onSubmit={handleStyleSubmit}>
                <DialogTitle id="form-dialog-name">{lang.addNewWineStyle}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{lang.addNewWineStyleContextMessage}</DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="wine-style-name"
                    value={styleDialogValue.name}
                    onChange={(event) => setStyleDialogValue({ ...styleDialogValue, name: event.target.value })}
                    label="wine-style-name"
                    type="text"
                  />
                </DialogContent>
                <DialogActions>
                  <button type="button" className="cx-button cx-button-cancel" onClick={handleStyleDialogClose}>
                    {lang.cancel}
                  </button>
                  <button type="submit" className="cx-button cx-button-save">
                    {lang.save}
                  </button>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              open={openDeleteDialog}
              onClose={handleDeleteDialogClose}
              aria-labelledby="wine-delete-dialog-title"
            >
              <DialogTitle id="wine-delete-dialog-title">{lang.deleteWineConfirmation}</DialogTitle>
              <DialogActions>
                <button type="button" className="cx-button cx-button-cancel" onClick={handleDeleteDialogClose}>
                  {lang.cancel}
                </button>
                <button type="button" className="cx-button cx-button-delete" onClick={onDeleteClick}>
                  {lang.delete}
                </button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(WineEdit);
