import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import { Controller } from 'react-hook-form';

const CxSwitch = ({ name, label, control, defaultValue, children, ...props }) => {
  const labelId = `${name}-label`;

  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <Switch labelId={labelId} label={label}>
            {children}
          </Switch>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};

CxSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.any.isRequired,
  defaultValue: PropTypes.any,
  children: PropTypes.object.isRequired,
};
CxSwitch.defaultProps = {};
export default CxSwitch;
