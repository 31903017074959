import React, { useEffect, useState } from 'react';
import { Loader, Panel } from '../../../core/components';

const PasswordManagerListItem = ({
  lang,
  items,
  onViewPasswordClick,
  onEditPasswordClick,
  onDeletePasswordClick,
  onCopyToClipboardPasswordClick,
  isLoading,
}) => {
  const [totalPasswords, setTotalPasswords] = useState(0);

  useEffect(() => {
    setTotalPasswords(items.length);
  }, [items]);

  const list = items.map((item) => (
    <li key={item.id}>
      <span onClick={() => onViewPasswordClick(item.id)}>{item.name}</span>
      <button className="cx-button cx-button-action" onClick={(e) => onCopyToClipboardPasswordClick(item.id, e)}>
        <i className="icon-clipboard" />
      </button>
      <button className="cx-button cx-button-edit" onClick={(e) => onEditPasswordClick(item.id, e)}>
        <i className="icon-pencil" />
      </button>
      <button className="cx-button cx-button-delete" onClick={(e) => onDeletePasswordClick(item.id, e)}>
        <i className="icon-trash-2" />
      </button>
    </li>
  ));

  return (
    <>
      <Panel title={lang.passwords} className="cx-password-manager-list">
        <div className="cx-password-manager-list-total">{`${lang.totalPasswords} (${totalPasswords})`}</div>
        {isLoading ? <Loader /> : list.length > 0 ? <ul>{list}</ul> : <li>No data found.</li>}
      </Panel>
    </>
  );
};

export default PasswordManagerListItem;
