import React, { useState, useCallback, useEffect } from 'react';
import './ListingEditForm.scss';

import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';

// Mui
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// CX
import { Loader, CxSelect, Panel } from '../../../../core/components';
import ListingAdminService from '../ListingAdmin.service';
import ListingTypesService from '../ListingTypes.service';
import BuildingClassService from '../buildingClass.service';
import LocationService from '../../../../core/services/location.service';
import ListingMetrics from '../ListingMetrics';
import { ListingEditAmenities } from './ListingEditAmenities';

export const ListingEditForm = ({ lang, history, emptyGuid, listingId, onListingIdChange }) => {
  const { enqueueSnackbar } = useSnackbar();

  const defaultRegionId = 'E335308C-399F-4088-93EE-AF52E52A992D';
  const industrialId = '88fcab78-d392-4304-95b4-c0f2629e5891';

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
  } = useForm();

  // Page load
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [id, setId] = useState(listingId);

  // Listing Types
  const [listingTypes, setListingTypes] = useState([]);
  let listingTypeId = watch('listingTypeId');

  const handleListingTypeChange = (value) => {
    // setListingTypes(value);
    setValue('listingTypeId', value, true);
  };

  const listingTypeList = listingTypes.map(({ id, name }) => ({ name: name, value: id }));

  // Building Class
  const [buildingClasses, setBuildingClasses] = useState([]);
  const [buildingClass, setBuildingClass] = useState('');

  const handleBuildingClassChange = (value) => {
    setBuildingClass(value);
    setValue('buildingClass', value, true);
  };

  const buildingClassList = buildingClasses.map(({ id, name }) => ({ name: name, value: id }));

  // Cities
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState(0);

  const handleCityChange = (value) => {
    setCityId(value);
    setValue('cityId', value, true);
  };

  const cityList = cities.map(({ id, name }) => ({ name: name, value: id }));

  // Amenities
  const [listingAmenities, setListingAmenities] = useState([]);
  const [amenities, setAmenities] = useState([]);

  const onAmenitiesChange = (data) => {
    const newAmenities = {};
    data.map(({ name, isChecked }) => {
      Object.defineProperty(newAmenities, name, {
        value: isChecked,
        writable: false,
      });
    });

    setAmenities(newAmenities);
  };

  const fixZeroIf0 = (value) => {
    return value !== 0 ? value : 0;
  };

  const getSwitchDefaultValue = (value) => value ?? false;

  const bindForm = (item) => {
    setId(item.listingId);

    setBuildingClass(item.buildingClass ?? '');
    setCityId(item.cityId);
    if (item.mezzanineSize > 0) {
      setHasMezzanine(true);
    }

    setListingAmenities([
      { name: 'hasSprinklers', isChecked: getSwitchDefaultValue(item.amenities.hasSprinklers), label: lang.sprinklers },
      { name: 'hasElevator', isChecked: getSwitchDefaultValue(item.amenities.hasElevator), label: lang.elevator },
      { name: 'hasSecurity', isChecked: getSwitchDefaultValue(item.amenities.hasSecurity), label: lang.security },
      { name: 'hasPowerPlant', isChecked: getSwitchDefaultValue(item.amenities.hasPowerPlant), label: lang.powerPlant },
      { name: 'hasWaterTank', isChecked: getSwitchDefaultValue(item.amenities.hasWaterTank), label: lang.waterTank },
      {
        name: 'hasLoadingDocks',
        isChecked: getSwitchDefaultValue(item.amenities.hasLoadingDocks),
        label: lang.loadingDocks,
      },
      { name: 'hasDriveThru', isChecked: getSwitchDefaultValue(item.amenities.hasDriveThru), label: lang.driveThru },
      {
        name: 'hasCentralAirConditioner',
        isChecked: getSwitchDefaultValue(item.amenities.hasCentralAirConditioner),
        label: lang.centralAirConditioner,
      },
      {
        name: 'hasElectricMeters',
        isChecked: getSwitchDefaultValue(item.amenities.hasElectricMeters),
        label: lang.waterMeters,
      },
      {
        name: 'hasWaterMeters',
        isChecked: getSwitchDefaultValue(item.amenities.hasWaterMeters),
        label: lang.electricMeters,
      },
      {
        name: 'hasAirConditionerOnNightsAndWeekends',
        isChecked: getSwitchDefaultValue(item.amenities.hasAirConditionerOnNightsAndWeekends),
        label: lang.airConditionerOnNightsAndWeekends,
      },
    ]);
    setAmenities(item.amenities);

    reset({
      id: item.id,
      countryId: item.countryId,
      regionId: item.regionId,
      cityId: item.cityId,
      zipCode: item.zipCode,
      address: item.address,
      listingIdentifier: item.listingIdentifier,
      latitude: item.latitude,
      longitude: item.longitude,
      saleType: `${item.saleType}`,
      listingTypeId: item.listingTypeId,
      description: item.description,
      buildingClass: item.buildingClass,
      buildingSize: fixZeroIf0(item.buildingSize),
      lotSize: fixZeroIf0(item.lotSize),
      numberOfParkingSpaces: fixZeroIf0(item.numberOfParkingSpaces),
      ceilingHeight: fixZeroIf0(item.ceilingHeight),
      hasMezzanine: item.hasMezzanine,
      mezzanineSize: fixZeroIf0(item.mezzanineSize),
      numberOfBuildings: item.numberOfBuildings,
      numberOfLoadingDocks: fixZeroIf0(item.numberOfLoadingDocks),
      numberOfColumns: fixZeroIf0(item.numberOfColumns),
      officeSpacePercent: fixZeroIf0(item.officeSpacePercent),
      buildingQty: fixZeroIf0(item.buildingQty),
      numberOfUnits: fixZeroIf0(item.numberOfUnits),
      numberOfFloors: fixZeroIf0(item.numberOfFloors),
      numberOfWaterMeters: fixZeroIf0(item.numberOfWaterMeters),
      numberOfElectricMeters: fixZeroIf0(item.numberOfElectricMeters),
      zoning: item.zoning,
      femaMap: item.femaMap,
      askingPrice: fixZeroIf0(item.askingPrice),
      annualPropertyIncome: fixZeroIf0(item.annualPropertyIncome),
      yearlyPropertyExpenses: fixZeroIf0(item.yearlyPropertyExpenses),
      amenities: item.amenities,
    });

    setLoadingCompleted(true);
  };

  const databind = useCallback((id) => {
    setId(id);
    if (id === emptyGuid) {
      const service = new ListingAdminService();
      service
        .getNewListingTemplate()
        .then((x) => {
          bindForm(x.data);
        })
        .catch(service.handleHttpError);
    } else {
      const service = new ListingAdminService();
      service
        .getListing(id)
        .then((x) => {
          bindForm(x.data);
        })
        .catch(service.handleHttpError);
    }
  }, []);

  useEffect(() => {
    const listingTypesService = new ListingTypesService();
    listingTypesService
      .getCommercial()
      .then((x) => {
        setListingTypes(x.data);
      })
      .catch(listingTypesService.handleHttpError);

    const buildingClassService = new BuildingClassService();
    buildingClassService
      .get()
      .then((x) => {
        setBuildingClasses(x.data);
      })
      .catch(buildingClassService.handleHttpError);

    const locationService = new LocationService();
    locationService
      .getCities(defaultRegionId)
      .then((x) => {
        setCities(x.data);
      })
      .catch(locationService.handleHttpError);

    if (id && id !== 'new') {
      databind(id);
    } else {
      databind(emptyGuid);
    }
  }, [databind]);

  // Events
  const onSubmit = (data) => {
    const service = new ListingAdminService();

    data.amenities = amenities;

    console.log(data);

    if (data.id && data.id !== emptyGuid) {
      service
        .update(data.id, data)
        .then((x) => {
          enqueueSnackbar('Listing has been updated!', { variant: 'success' });
        })
        .catch(service.handleHttpError);
    } else {
      service
        .create(data)
        .then((x) => {
          bindForm(x.data);
          handleListingIdChange(x.data.id);
          enqueueSnackbar('New listing created!', { variant: 'success' });
        })
        .catch(service.handleHttpError);
    }
  };

  const handleEditCancelClick = () => {
    history.push(`/listings`);
  };

  const handleListingIdChange = (newId) => {
    onListingIdChange(newId);
  };

  // Mezzanine
  const [hasMezzanine, setHasMezzanine] = useState(false);
  const handleMezzanineSwitchChange = (event) => {
    setHasMezzanine(event.target.checked);
  };

  // Metrics
  const [metrics, setMetrics] = useState(new ListingMetrics());
  let askingPrice = watch('askingPrice');
  let annualPropertyIncome = watch('annualPropertyIncome');
  let yearlyPropertyExpenses = watch('yearlyPropertyExpenses');
  let buildingSize = watch('buildingSize');
  let numberOfUnits = watch('numberOfUnits');
  let numberOfParkingSpaces = watch('numberOfParkingSpaces');

  useEffect(() => {
    if (!askingPrice || askingPrice === 0) return;
    const squareFeet = buildingSize;
    const squareMeters = buildingSize;

    metrics.pricePerSquareFootage = squareFeet > 0 ? askingPrice / squareFeet : 0;
    metrics.pricePerSquareMeter = squareMeters > 0 ? askingPrice / squareMeters : 0;
    metrics.pricePerUnit = numberOfUnits === 0 ? 0 : askingPrice / numberOfUnits;
    metrics.incomePerSquareFootage =
      annualPropertyIncome >= 0 && squareFeet > 0 ? annualPropertyIncome / squareFeet : 0;
    metrics.incomePerSquareMeter = squareMeters > 0 ? annualPropertyIncome / squareMeters : 0;
    metrics.incomePerUnit = numberOfUnits === 0 ? 0 : annualPropertyIncome / numberOfUnits;
    metrics.expensesPerSquareFootage =
      yearlyPropertyExpenses >= 0 && squareFeet > 0 ? yearlyPropertyExpenses / squareFeet : 0;
    metrics.expensesPerSquareMeters =
      yearlyPropertyExpenses >= 0 && squareMeters > 0 ? yearlyPropertyExpenses / squareMeters : 0;
    metrics.expensePerUnit = numberOfUnits === 0 ? 0 : yearlyPropertyExpenses / numberOfUnits;
    metrics.propertyNoi = annualPropertyIncome - yearlyPropertyExpenses;

    metrics.noiPerSquareFeet = metrics.propertyNoi / squareFeet;
    metrics.noiPerSquareMeter = metrics.propertyNoi / squareMeters;

    metrics.noiPerUnit = metrics.propertyNoi / numberOfUnits;
    metrics.operatingExpenseRatio = yearlyPropertyExpenses / annualPropertyIncome;
    metrics.noiProfitMarginRatio = metrics.propertyNoi / annualPropertyIncome;
    metrics.paybackPeriod = askingPrice / metrics.propertyNoi;
    metrics.parkingRatio = numberOfParkingSpaces / (buildingSize / 1000);

    // console.log(metrics);
    setMetrics(metrics);
  }, [askingPrice, annualPropertyIncome, yearlyPropertyExpenses, buildingSize, numberOfUnits, numberOfParkingSpaces]);

  // Coordinates
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((x) => {
        setValue('latitude', x.coords.latitude);
        setValue('longitude', x.coords.longitude);
      });
    } else {
      // x.innerHTML = "Geolocation is not supported by this browser.";
      console.log('Geolocation is not supported by this browser.');
    }
  };

  return !loadingCompleted ? (
    <Loader />
  ) : (
    <>
      <div className="cx-admin-listing-edit-form form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register('id')} />
          <input type="hidden" {...register('countryId')} />
          <input type="hidden" {...register('regionId')} />
          <input type="hidden" {...register('amenities')} />

          <Panel title={lang.general} className="general">
            <div>
              <Controller
                render={({ field }) => (
                  <RadioGroup row aria-label="saleType" {...field}>
                    <FormControlLabel value="1" control={<Radio />} label={lang.sale} />
                    <FormControlLabel value="2" control={<Radio />} label={lang.lease} />
                  </RadioGroup>
                )}
                name="saleType"
                control={control}
              />
            </div>

            <div>
              <TextField
                id="description"
                name="description"
                label={lang.description}
                multiline
                rows={2}
                {...register('description', { maxLength: { value: 1024 } })}
                variant="outlined"
                fullWidth
              />
              {errors.description && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>
          </Panel>

          <Panel title={lang.location}>
            <div>
              <CxSelect
                name="cityId"
                label={lang.municipality}
                fullWidth
                items={cityList}
                defaultValue={cityId}
                onChange={handleCityChange}
              />

              {errors.cityId && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <TextField
                name="zipCode"
                label={lang.zipCode}
                {...register('zipCode', { required: { value: true } })}
                variant="outlined"
                fullWidth
              />
              {errors.zipCode && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <TextField
                name="address"
                label={lang.propertyAddress}
                {...register('address', { required: { value: true } })}
                variant="outlined"
                fullWidth
              />
              {errors.address && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <TextField
                name="listingIdentifier"
                label={lang.taxIdNumber}
                {...register('listingIdentifier', { required: { value: true }, maxLength: { value: 20 } })}
                helperText={`${lang.example} xxx-xxx-xxx-xx-xxx`}
                variant="outlined"
                fullWidth
              />
              {errors.listingIdentifier && (
                <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
              )}
            </div>

            <div className="coordinates">
              <h3>{lang.coordinates}</h3>
              <div>
                <div className="w3-half">
                  <TextField
                    name="latitude"
                    label={lang.latitude}
                    {...register('latitude', { required: { value: true }, maxLength: { value: 30 } })}
                    step="any"
                    variant="outlined"
                    fullWidth
                  />
                  {errors.latitude && (
                    <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
                  )}
                </div>
                <div className="w3-half">
                  <TextField
                    name="longitude"
                    label={lang.longitude}
                    {...register('longitude', { required: { value: true }, maxLength: { value: 30 } })}
                    variant="outlined"
                    fullWidth
                  />
                  {errors.longitude && (
                    <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
                  )}
                </div>
                <div>
                  <button type="button" className="cx-nobutton" onClick={getCurrentLocation} title={lang.useMyLocation}>
                    <i className="icon-icon-pinpoint"></i>
                  </button>
                </div>
              </div>
            </div>
          </Panel>

          <Panel title={lang.listingInformation}>
            <div>
              <CxSelect
                name="listingTypeId"
                label={lang.propertyType}
                fullWidth
                items={listingTypeList}
                defaultValue={listingTypeId}
                onChange={handleListingTypeChange}
              />

              {errors.listingType && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>
            <div>
              <CxSelect
                name="buildingClass"
                label={lang.buildingClass}
                fullWidth
                items={buildingClassList}
                defaultValue={buildingClass}
                onChange={handleBuildingClassChange}
              />
            </div>

            <div>
              <TextField
                name="buildingSize"
                label={lang.buildingSizeSqFt}
                {...register('buildingSize', { required: true, maxLength: 20 })}
                type="Number"
                variant="outlined"
                fullWidth
              />
              {errors.buildingSize && (
                <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
              )}
            </div>
            <div>
              <TextField
                name="lotSize"
                label={lang.lotSizeSqMt}
                {...register('lotSize', { required: true })}
                inputProps={{
                  step: 0.1,
                }}
                type="Number"
                variant="outlined"
                fullWidth
              />
              {errors.lotSizeSqFt && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <TextField
                name="numberOfParkingSpaces"
                label={lang.parkingSpaces}
                {...register('numberOfParkingSpaces', { required: true })}
                type="Number"
                variant="outlined"
                fullWidth
              />
              {errors.numberOfParkingSpaces && (
                <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
              )}
            </div>

            {listingTypeId === industrialId ? (
              <>
                <div>
                  <TextField
                    name="ceilingHeight"
                    label={lang.ceilingHeightFt}
                    {...register('ceilingHeight')}
                    type="Number"
                    variant="outlined"
                    fullWidth
                  />
                  {errors.ceilingHigh && (
                    <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
                  )}
                </div>

                <div className="mezzanine">
                  <label>{lang.hasMezzanine}</label>

                  <div>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            name="hasMezzanine"
                            onChange={handleMezzanineSwitchChange}
                            {...register('hasMezzanine')}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        }
                      />
                    </div>
                    <div>
                      <TextField
                        name="mezzanineSize"
                        label={lang.mezzanineSizeSqFt}
                        {...register('mezzanineSize', { required: hasMezzanine })}
                        disabled={!hasMezzanine}
                        type="Number"
                        variant="outlined"
                        fullWidth
                      />
                      {errors.mezzanineSize && (
                        <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <TextField
                    name="numberOfLoadingDocks"
                    label={lang.numberOfLoadingDocks}
                    {...register('numberOfLoadingDocks')}
                    type="Number"
                    variant="outlined"
                    fullWidth
                  />
                  {errors.loadingDockQty && (
                    <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
                  )}
                </div>

                <div>
                  <TextField
                    name="numberOfColumns"
                    label={lang.numberOfColumns}
                    {...register('numberOfColumns')}
                    type="Number"
                    variant="outlined"
                    fullWidth
                  />
                  {errors.columnQty && <span className="cx-form-validation-message">Max Length</span>}
                </div>

                <div>
                  <TextField
                    name="officeSpacePercent"
                    label={lang.officePercent}
                    {...register('officeSpacePercent')}
                    type="Number"
                    variant="outlined"
                    fullWidth
                  />
                  {errors.officeSpacePercent && (
                    <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
                  )}
                </div>
              </>
            ) : null}

            <div>
              <TextField
                name="numberOfBuildings"
                label={lang.numberOfBuildings}
                {...register('numberOfBuildings', { required: true })}
                type="Number"
                variant="outlined"
                fullWidth
              />
              {errors.numberOfBuildings && (
                <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
              )}
            </div>

            <div>
              <TextField
                name="numberOfUnits"
                label={lang.numberOfUnits}
                {...register('numberOfUnits', { required: true })}
                type="Number"
                variant="outlined"
                fullWidth
              />
              {errors.unitsQty && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <TextField
                name="numberOfFloors"
                label={lang.numberOfFloors}
                {...register('numberOfFloors', { required: true })}
                type="Number"
                variant="outlined"
                fullWidth
              />
              {errors.numberOfFloors && (
                <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
              )}
            </div>

            <div>
              <TextField
                name="numberOfWaterMeters"
                label={lang.numberOfWaterMeters}
                {...register('numberOfWaterMeters', { required: true })}
                type="Number"
                variant="outlined"
                fullWidth
              />
              {errors.numberOfWaterMeters && (
                <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
              )}
            </div>

            <div>
              <TextField
                name="numberOfElectricMeters"
                label={lang.numberOfElectricMeters}
                {...register('numberOfElectricMeters', { required: true })}
                type="Number"
                variant="outlined"
                fullWidth
              />
              {errors.numberOfElectricMeters && (
                <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
              )}
            </div>

            <div>
              <TextField name="zoning" label={lang.zoning} {...register('zoning')} variant="outlined" fullWidth />
              {errors.zoning && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <TextField name="femaMap" label={lang.femaMap} {...register('femaMap')} variant="outlined" fullWidth />
              {errors.fema && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>
          </Panel>

          <Panel title={lang.financialInformation}>
            <div>
              <TextField
                name="askingPrice"
                label={lang.askingPrice}
                {...register('askingPrice', { required: true })}
                type="Number"
                variant="outlined"
                fullWidth
              />
              {errors.askingPrice && <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>}
            </div>

            <div>
              <TextField
                name="annualPropertyIncome"
                label={lang.annualPropertyIncome}
                {...register('annualPropertyIncome', { required: true })}
                type="Number"
                variant="outlined"
                fullWidth
              />
              {errors.annualPropertyIncome && (
                <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
              )}
            </div>

            <div>
              <TextField
                name="yearlyPropertyExpenses"
                label={lang.yearlyPropertyExpenses}
                {...register('yearlyPropertyExpenses', { required: true })}
                type="Number"
                variant="outlined"
                fullWidth
              />
              {errors.yearlyPropertyExpenses && (
                <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
              )}
            </div>
          </Panel>

          {listingAmenities.length > 0 ? (
            <Panel title={lang.amenities} className="amenities">
              <ListingEditAmenities lang={lang} amenities={listingAmenities} onChange={onAmenitiesChange} />
            </Panel>
          ) : null}

          {id !== emptyGuid ? (
            <Panel title={lang.metrics}>
              <div>
                <TextField
                  label={lang.pricePerSquareFootage}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={metrics.pricePerSquareFootage}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.pricePerSquareMeter}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.pricePerSquareMeter}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.pricePerUnit}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.pricePerUnit}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.incomePerSquareFootage}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.incomePerSquareFootage}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.incomePerSquareMeter}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.incomePerSquareMeter}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.incomePerUnit}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.incomePerUnit}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.expensesPerSquareFootage}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.expensesPerSquareFootage}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.expensesPerSquareMeters}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.expensesPerSquareMeters}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.expensePerUnit}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.expensePerUnit}
                  variant="outlined"
                  fullWidth
                />
              </div>

              <div>
                <TextField
                  label={lang.propertyNoi}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.propertyNoi}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.noiPerSquareFeet}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.noiPerSquareFeet}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.noiPerSquareMeter}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.noiPerSquareMeter}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.noiPerUnit}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.noiPerUnit}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.operatingExpenseRatio}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.operatingExpenseRatio}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.noiProfitMarginRatio}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.noiProfitMarginRatio}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.paybackPeriod}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.paybackPeriod}
                  variant="outlined"
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  label={lang.parkingRatio}
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue={metrics.parkingRatio}
                  variant="outlined"
                  fullWidth
                />
              </div>
            </Panel>
          ) : null}

          <div className="cx-admin-footer-actions">
            <button type="button" className="cx-button cx-button-cancel" onClick={handleEditCancelClick}>
              {lang.cancel}
            </button>
            <button type="submit" className="cx-button cx-button-save">
              {lang.save}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default withRouter(ListingEditForm);
