import React, { useEffect, useState, useRef } from 'react';
import './passwordManager.scss';

import useApi from '../../../core/useApi';

import useCopy from 'use-copy';

import responsive from '../../../core/responsive';
import { helpers } from '../../../core/helpers';
import { Breadcrumb, Modal, Panel, CxConfirm } from '../../../core/components';

import PasswordManagerListItem from './PasswordManagerListItem';
import { PasswordSearchRequest } from './PasswordSearch.dto';

// State
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

// MUI
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { useSnackbar } from 'notistack';

const PasswordManager = () => {
  const lang = useSelector((state) => state.app.lang);
  const { get, post, put, del } = useApi();
  const [isLoading, setIsLoading] = useState(false);

  const searchRef = useRef();
  const [isSearching, setIsSearching] = useState(false);
  const [searchRequest, setSearchRequest] = useState(new PasswordSearchRequest());
  const { enqueueSnackbar } = useSnackbar();
  const breadcrumb = [
    { name: lang.dashboard, path: '/dashboard' },
    { name: lang.passwordManager, path: null },
  ];

  const [showEditForm, setShowEditForm] = useState(false);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [secureData, setSecureData] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();

  // Responsive ====================================================================
  const [isLarge, setIsLarge] = useState(window.innerWidth >= responsive.mdMinWidth);
  // timeOutFunctionId stores a numeric ID which is used by clearTimeOut to reset timer.
  var timeOutFunctionId;
  window.onresize = function (event) {
    // clearTimeOut() resets the setTimeOut() timer due to this the function in setTimeout() is fired after we are done resizing.
    clearTimeout(timeOutFunctionId);

    // setTimeout returns the numeric ID which is used by clearTimeOut to reset the timer.
    timeOutFunctionId = setTimeout(() => {
      const width = event.srcElement.innerWidth;

      if (width < responsive.mdMinWidth) {
        if (isLarge) {
          setIsLarge(false);
        }
      }

      if (width > responsive.mdMinWidth) {
        if (!isLarge) {
          setIsLarge(true);
        }
      }
    }, 500);
  };

  const getPasswords = async () => {
    setIsLoading(true);
    try {
      const response = await get('password-manager');

      reset({
        id: '',
        name: '',
        userName: '',
        password: '',
        passwordLength: 20,
        comment: '',
      });

      setItems(response);
      setFilteredItems(response);

      // Set search defaults (search and sorting will be done in the front end)
      const search = { ...searchRequest };
      search.search.sort = 'name';
      search.search.sortDirection = 'asc';
      setSearchRequest(search);

      return response;
    } finally {
      setIsLoading(false);
    }
  };

  const createPassword = async (data) => {
    setIsLoading(true);
    try {
      const response = await post(`password-manager`, data);

      reset();
      const newItems = [...items];
      newItems.unshift(response);
      setItems(newItems);
      setFilteredItems(newItems);
      setShowEditForm(false);
      enqueueSnackbar(lang.passwordSaved, {
        variant: 'success',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updatePassword = async (data) => {
    setIsLoading(true);
    try {
      const response = await put(`password-manager/${data.id}`, data);

      reset();
      data.modifyDate = new Date();
      const updatedItems = items.filter((x) => x.id !== data.id);
      updatedItems.unshift(data);
      setItems(updatedItems);
      setFilteredItems(updatedItems);
      reset({
        id: '',
        name: '',
        password: '',
        passwordLength: 20,
        comment: '',
      });
      setShowEditForm(false);
      enqueueSnackbar(lang.passwordSaved, {
        variant: 'success',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPasswords();
  }, []);

  const onSubmit = (data) => {
    if (data.id === '') {
      createPassword(data);
    } else {
      updatePassword(data);
    }
  };

  const generatePasswordClick = async () => {
    const length = getValues('passwordLength');

    const response = await get(`password-manager/generate/${length}`);

    setValue('password', response, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const showPasswordModal = useRef();

  const onViewPasswordClick = async (id) => {
    const response = await get(`password-manager/${id}/value`);
    const pwd = Object.assign({}, response);
    pwd.id = id;
    setSecureData(pwd);
    showPasswordModal.current.toggleModal(true);
  };

  const onEditPasswordClick = async (id) => {
    const pw = items.filter((x) => x.id === id)[0];

    const response = await get(`password-manager/${id}/value`);

    reset({
      id: pw.id,
      name: pw.name,
      userName: response.userName,
      password: response.password,
      passwordLength: response.password.length,
      comment: response.comment,
    });
    setShowEditForm(true);
  };

  const newPasswordClick = () => {
    reset({
      id: '',
      name: '',
      password: '',
      passwordLength: 20,
      comment: '',
    });
    setShowEditForm(true);
  };

  const onCancelClick = () => {
    reset({
      id: '',
      name: '',
      password: '',
      passwordLength: 20,
      comment: '',
    });
    setShowEditForm(false);
  };

  // Delete
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [pwDeleteId, setPwDeleteId] = useState();
  const onDeletePasswordClick = (id, e) => {
    e.preventDefault();
    setPwDeleteId(id);
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirm = async () => {
    setShowDeleteConfirm(false);

    await del(`password-manager/${pwDeleteId}`);

    const newItems = items.filter((x) => x.id !== pwDeleteId);
    setItems(newItems);
    setFilteredItems(newItems);
    enqueueSnackbar(lang.passwordDeleted, {
      variant: 'success',
    });

    setPwDeleteId();
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirm(false);
  };

  // Copy to clipboard
  const [clipboard, setClipboard] = useState(null);
  const [copied, copy, setCopied] = useCopy(clipboard);

  useEffect(() => {
    if (clipboard !== undefined && clipboard !== null) {
      copy();

      setTimeout(() => {
        setCopied(false);
        setClipboard(null);
      }, 3000);
    }
  }, [clipboard]);

  const onCopyToClipboardUsernameClick = (name) => {
    setClipboard(name);
    enqueueSnackbar(lang.usernameCopiedToClipboard, {
      variant: 'info',
    });
  };

  const onCopyToClipboardPasswordClick = async (id) => {
    const response = await get(`password-manager/${id}/value`);
    setClipboard(response.password);
    enqueueSnackbar(lang.passwordCopiedToClipboard, {
      variant: 'info',
    });
  };

  // Search
  const clearSearchClick = () => {
    searchRef.current.value = null;
    const search = { ...searchRequest };
    search.search.text = null;
    setSearchRequest(search);
    setFilteredItems(items);
    setIsSearching(false);
  };

  const onSearchInputChange = (event) => {
    const search = { ...searchRequest };
    search.search.text = event.target.value;
    setSearchRequest(search);

    if (!search.search.text) {
      clearSearchClick();
      return;
    }

    const newItems = items.filter((x) => x.name.toLowerCase().includes(search.search.text.toLowerCase()));
    setFilteredItems(newItems);
    setIsSearching(true);
  };

  // Sorting
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSortClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSortClick = (sortField) => {
    const request = { ...searchRequest };
    const { sort } = request.search;

    if (sortField === sort) {
      request.search.sortDirection = request.search.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      request.search.sort = sortField;
      request.search.sortDirection = 'asc';
    }

    const newItems = items.sort((a, b) => {
      if (sortField === 'name') {
        if (request.search.sortDirection === 'asc') {
          return helpers.sortTextAsc(a.name, b.name);
        }
        return helpers.sortTextDesc(a.name, b.name);
      }

      if (request.search.sortDirection === 'asc') {
        return helpers.sortDateTimeAsc(a.lastUpdated, b.lastUpdated);
      }
      return helpers.sortDateTimeDesc(a.lastUpdated, b.lastUpdated);
    });

    setFilteredItems(newItems);
    setSearchRequest(request);
    setAnchorEl(null);
  };

  return (
    <>
      <Breadcrumb items={breadcrumb} />

      <div className="cx-password-manager">
        {!showEditForm ? (
          <div className="cx-password-manager-search">
            <Paper component="form" sx={{ p: '0px 8px', display: 'flex', alignItems: 'center' }}>
              <InputBase
                type="text"
                inputRef={searchRef}
                placeholder={lang.searchPassword}
                onChange={onSearchInputChange}
                sx={{ ml: 1, flex: 1 }}
                inputProps={{ 'aria-label': lang.searchPassword }}
              />
              {isSearching ? (
                <IconButton type="submit" aria-label="clear-search" onClick={clearSearchClick} size="large">
                  <i className="icon-times" />
                </IconButton>
              ) : null}
              <Divider orientation="vertical" />
              <IconButton type="button" aria-label="sort" onClick={handleSortClick} size="large">
                <i className="icon-sort-amount-asc" />
              </IconButton>
              <Divider orientation="vertical" />
              <button type="button" className="cx-button cx-button-new" onClick={newPasswordClick}>
                {isLarge ? lang.newPassword : <i className="icon-plus" />}
              </button>
            </Paper>
          </div>
        ) : null}
        {showEditForm ? (
          <Panel title={lang.newPassword}>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" {...register('id')} />
                <div>
                  <TextField
                    name="name"
                    label={lang.name}
                    {...register('name', {
                      required: { value: true, message: lang.validationFieldRequired },
                      maxLength: { value: 50, message: 'No more than 50 chars please.' },
                    })}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    autoComplete="off"
                    fullWidth
                  />
                  {errors.name && <span className="cx-form-validation-message">{errors?.name?.message}</span>}
                </div>
                <div>
                  <TextField
                    name="userName"
                    label={lang.userName}
                    {...register('userName', {
                      required: { value: true, message: lang.validationFieldRequired },
                      maxLength: { value: 128, message: 'No more than 128 chars please.' },
                    })}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    autoComplete="off"
                    fullWidth
                  />
                  {errors.userName && <span className="cx-form-validation-message">{errors?.userName?.message}</span>}
                </div>
                <div className="cx-password-manager-password">
                  <div>
                    <TextField
                      name="password"
                      label={lang.password}
                      {...register('password', {
                        required: { value: true, message: lang.validationFieldRequired },
                        maxLength: { value: 50, message: 'No more than 50 chars please.' },
                      })}
                      type="text"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      autoComplete="off"
                      fullWidth
                    />
                    {errors.password && (
                      <span className="cx-form-validation-message">{lang.validationFieldRequired}</span>
                    )}
                  </div>
                  <div>
                    <TextField
                      name="passwordLength"
                      {...register('passwordLength', {
                        required: { value: true, message: lang.validationFieldRequired },
                        min: { value: 4, message: 'Password needs to be at least 4 chars.' },
                      })}
                      type="number"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                    {errors.passwordLength && (
                      <span className="cx-form-validation-message">{errors.passwordLength.message}</span>
                    )}
                  </div>
                  <div onClick={generatePasswordClick}>
                    <i className="icon-arrows-ccw" />
                  </div>
                </div>

                <div>
                  <TextField
                    name="comment"
                    label={lang.comment}
                    {...register('comment', {
                      maxLength: { value: 1024, message: 'No more than 1024 chars please.' },
                    })}
                    type="text"
                    variant="outlined"
                    multiline
                    rows={2}
                    maxRows={3}
                    InputLabelProps={{ shrink: true }}
                    autoComplete="off"
                    fullWidth
                  />
                  {errors.comment && <span className="cx-form-validation-message">{errors?.comment?.message}</span>}
                </div>

                <div className="cx-admin-footer-actions">
                  <button type="button" className="cx-button cx-button-cancel" tabIndex="-1" onClick={onCancelClick}>
                    {lang.cancel}
                  </button>
                  <button type="submit" className="cx-button cx-button-save">
                    {lang.save}
                  </button>
                </div>
              </form>
            </div>
          </Panel>
        ) : (
          <>
            <PasswordManagerListItem
              lang={lang}
              items={filteredItems}
              onViewPasswordClick={onViewPasswordClick}
              onCopyToClipboardPasswordClick={onCopyToClipboardPasswordClick}
              onEditPasswordClick={onEditPasswordClick}
              onDeletePasswordClick={onDeletePasswordClick}
              isLoading={isLoading}
            />
          </>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="cx-beer-search-sort">
            <h3>Sort By</h3>
            <ul>
              <li onClick={() => onSortClick('name')}>
                {lang.name}
                {searchRequest.search.sort === 'name' ? (
                  searchRequest.search.sortDirection === 'asc' ? (
                    <i className="icon-sort-amount-asc" />
                  ) : (
                    <i className="icon-sort-amount-desc" />
                  )
                ) : null}
              </li>
              <li onClick={() => onSortClick('createDate')}>
                {lang.uLastUpdated}
                {searchRequest.search.sort === 'lastUpdated' ? (
                  searchRequest.search.sortDirection === 'asc' ? (
                    <i className="icon-sort-amount-asc" />
                  ) : (
                    <i className="icon-sort-amount-desc" />
                  )
                ) : null}
              </li>
            </ul>
          </div>
        </Popover>

        <CxConfirm
          lang={lang}
          type="delete"
          message={`${lang.deleteConfirmationMessage}"${lang.password}"?`}
          show={showDeleteConfirm}
          confirmButtonText={lang.delete}
          onConfirmClick={handleDeleteConfirm}
          onCancelClick={handleDeleteCancel}
        />

        <Modal ref={showPasswordModal} title="Password View" className="cx-password-manager-secrets-view">
          {secureData ? (
            <>
              <code>
                <button
                  className="cx-button cx-button-action"
                  onClick={() => onCopyToClipboardUsernameClick(secureData.userName)}
                >
                  <i className="icon-clipboard" />
                </button>
                <span>{secureData.userName}</span>
              </code>
              <code>
                <button
                  className="cx-button cx-button-action"
                  onClick={(e) => onCopyToClipboardPasswordClick(secureData.id, e)}
                >
                  <i className="icon-clipboard" />
                </button>
                <span>{secureData.password}</span>
              </code>
              {secureData.comment ? <code>{secureData.comment}</code> : null}
            </>
          ) : null}
        </Modal>
      </div>
    </>
  );
};

export default PasswordManager;
