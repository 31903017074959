import ServiceBase from '../../../core/services/serviceBase';

export default class TodoService extends ServiceBase {
  getTodos = () => super.http.get('todo');
  getTodo = (todoId) => super.http.get(`todo/${todoId}`);
  createTodo = (todo) => super.http.post('todo', todo);
  updateTodo = (todo) => super.http.put(`todo/${todo.id}`, todo);
  deleteTodo = (todoId) => super.http.delete(`todo/${todoId}`);
  getTodoItems = (todoId) => super.http.get(`todo/${todoId}/items`);
  getTodoItem = (todoId, id) => super.http.get(`todo/${todoId}/items/${id}`);
  createTodoItem = (todoId, item) => super.http.post(`todo/${todoId}/items`, item);
  updateTodoItem = (todoId, item) => super.http.put(`todo/${todoId}/items/${item.id}`, item);
  updateTodoItemStatus = (todoId, item) => super.http.put(`todo/${todoId}/items/${item.id}/status`, item);
  deleteTodoItem = (todoId, id) => super.http.delete(`todo/${todoId}/items/${id}`);
}
