import React, { useEffect, useState } from 'react';
import './Notification.scss';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { Loader, EmptyState, Breadcrumb, Card } from '../../../core/components';
import useApi from '../../../core/useApi';

import NotificationService from './Notification.service';
import SharedOrchestratorService from '../sharedContent/SharedOrchestrator.service';

const Notification = ({ history, match }) => {
  const { get } = useApi();
  const lang = useSelector((state) => state.app.lang);
  const breadcrumb = [{ name: lang.notifications, path: null }];
  const [notificationId] = useState(match.params.id);
  const [message, setMessage] = useState();
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getNotification = async (notificationId) => {
    const response = await get(`notifications/${notificationId}`)
      .then((x) => {
        if (x) {
          const msg = JSON.parse(x.data.notification.message);
          setMessage(msg);
          setLoadingCompleted(true);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setMessage({
          notificationType: 'error',
        });
      });
    //setStatus(response);
    setLoadingCompleted(true);
  };

  useEffect(() => {
    getNotification(notificationId);
  }, []);

  const onActionButtonClick = () => {
    const service = new SharedOrchestratorService();
    service
      .confirmShare(notificationId)
      .then((x) => {
        if (x) {
          console.log(x);
          switch (x.data.contentName) {
            case 'Notebook':
              history.push('/notebooks');
              break;
            default:
              history.push('/dashboard');
              break;
          }
        }
      })
      .catch((e) => service.handleHttpError(e, enqueueSnackbar));
  };

  const onCancelClick = () => {
    history.push('/dashboard');
  };

  const renderNotification = (notificationType) => {
    // console.log(notificationType);
    switch (notificationType) {
      case 'Notebook':
        var msg2 = lang.notebookSharedPreNotificationInstructions2;
        msg2 = msg2.replace('#fromName', message.fromName);
        msg2 = msg2.replace('#notificationType', notificationType);
        msg2 = msg2.replace('#contentName', message.content.name);

        var msg3 = lang.notebookSharedPreNotificationInstructions3;

        return (
          <>
            <Card>
              <div>
                <p>{lang.notebookSharedPreNotificationInstructions1.replace('#toName', message.toName)}</p>
                <p>{msg2}</p>
                <p>{msg3}</p>
                <button type="button" className="cx-button cx-button-cancel" onClick={onCancelClick}>
                  {lang.cancel}
                </button>
                <button type="button" className="cx-button cx-button-action" onClick={onActionButtonClick}>
                  {lang.continue}
                </button>
              </div>
            </Card>
          </>
        );
      default:
        return (
          <EmptyState
            title={lang.notificationConfirmationLoadingError}
            actionText={lang.dashboard}
            onActionClick={onCancelClick}
          />
        );
    }
  };

  return (
    <>
      <Breadcrumb items={breadcrumb} />
      <div className="cx-notification">
        {loadingCompleted ? <>{renderNotification(message.notificationType)}</> : <Loader />}
      </div>
    </>
  );
};

Notification.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default Notification;
