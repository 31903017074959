import { configService } from './config.service';

import axios from 'axios';

export default class ServiceBase {
  authService;

  ax = axios.create({
    baseURL: configService.apiUrl,
  });

  get http() {
    this.ax.defaults.headers.common['x-client-id'] = configService.appId;
    return this.ax;
  }

  handleHttpError(ex, alert) {
    console.log(ex);

    if (statusCode === 401 || statusCode === 403) {
      // sessionStorage.clear();
      // window.location = window.location.origin + '/login';
      // return;
      return ex.response;
    }

    if (ex.response) {
      var statusCode = ex.response.status;
      var errors = ex.response.data;

      if (statusCode === 409) {
        console.log(errors);
        if (errors.messages.length > 0) {
          errors.messages.map((x) => {
            alert(x.message, {
              variant: x.messageType.toLowerCase(),
              autoHideDuration: 10000,
            });
          });
        }

        return;
      }

      if (statusCode === 400) {
        if (ex && ex.response && ex.response.data) {
          console.log(ex.response.data);
          let message = ex.response.data;
          if (ex.response.data && ex.response.data.message && ex.response.data.status) {
            message = `${ex.response.data.message}. STATUS:${ex.response.data.status}`;
          }
          alert(message, { variant: 'error', autoHideDuration: 10000 });
          const errors = ex.response.data.errors;
          if (errors) {
            for (var c in errors) {
              if (errors.hasOwnProperty(c)) {
                alert(`${c}: ${errors[c]}`, { variant: 'error', autoHideDuration: 10000 });
              }
            }
          }
        }

        return;
      }

      if (statusCode < 500) {
        alert(ex.response.data.message, {
          variant: 'error',
          autoHideDuration: 10000,
        });
        return;
      }

      var msg = ex.response.data[0];
      alert(`${msg.message} ${msg.body}`, { variant: 'error', autoHideDuration: 10000 });
      console.log(ex.toJSON());
    } else if (ex.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(ex.request);
      window.location = window.location.origin + '/error';
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', ex.message);

      window.location = window.location.origin + '/error';
    }
    // console.log(ex.config);
  }
}
