import { SETWORKINGNOTEBOOK } from './notes.actions';

const getInitWorkingNotebook = () => {
  if (localStorage.getItem(SETWORKINGNOTEBOOK)) {
    return null;
  }
  return JSON.parse(localStorage.getItem(SETWORKINGNOTEBOOK));
};

const initialState = {
  workingNotebook: getInitWorkingNotebook(),
};

export const notes = (state = initialState, action) => {
  let newData = Object.assign({}, action.data);
  switch (action.type) {
    case SETWORKINGNOTEBOOK:
      localStorage.setItem(SETWORKINGNOTEBOOK, JSON.stringify(newData));
      return {
        ...state,
        workingNotebook: newData,
      };
    default:
      return state;
  }
};
