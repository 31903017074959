import React, { useState } from 'react';
import './CollapsePanel.scss';
import PropTypes from 'prop-types';

const CollapsePanel = ({ title, className, open, children }) => {
  // Set if panel is open or close by default.
  let defaultState = false;
  if (open != null) {
    defaultState = open;
  }

  const [isActive, setActive] = useState(defaultState);

  const handleClick = () => {
    setActive(!isActive);
  };

  return (
    <div className={className == null ? 'cx-collapse-panel' : `cx-collapse-panel ${className}`}>
      <button type="button" className={isActive ? 'collapsible active' : 'collapsible'} onClick={handleClick}>
        <span>{title}</span>
        {isActive ? <i className="icon-chevron-down" /> : <i className="icon-chevron-up" />}
        <p>&nbsp;</p>
      </button>
      <div className="content" style={{ display: isActive ? 'block' : '' }}>
        {children}
      </div>
    </div>
  );
};

CollapsePanel.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.object.isRequired,
};
CollapsePanel.defaultProps = {};

export default CollapsePanel;
