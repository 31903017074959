import React, { useState, useRef, useEffect } from 'react';
import './NoteEdit.scss';

import TextareaAutosize from '@mui/material/TextareaAutosize';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

import { configService } from '../../../../core/services/config.service';
import { helpers } from '../../../../core/helpers';
import { CxConfirm } from '../../../../core/components';

const NoteEdit = ({ lang, note, onNoteSaved, onNoteCancel, onNoteAutoSave, onNoteDelete, noteContainerHeight }) => {
  const titleRef = useRef();
  const editorRef = useRef();
  const [title, setTitle] = useState();
  const [body, setBody] = useState();

  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };

  useEffect(() => {
    setTitle(note.title);
    setBody(note.body);

    titleRef.current.value = note.title;
    editorRef.current.setContents(note.body);
  }, [note]);

  // Auto Save
  useEffect(() => {
    if (note && (note.accessLevel === 'Owner' || note.accessLevel === 'Edit' || note.accessLevel === 'EditAndShare')) {
      const timer = setInterval(() => {
        if (configService.environment === 'development') {
          // console.log('onNoteAutoSave', note.id, title, body, 'hasChanged');
          const modifiedState = {
            name: 'Note onNoteAutoSave',
            hasChanged: helpers.hasChanged(note, { ...note, title: title, body: body }),
          };

          // console.log(modifiedState);
        }
        if (
          note.id !== '' &&
          title !== '' &&
          body !== '' &&
          helpers.hasChanged(note, { ...note, title: title, body: body })
        ) {
          // if (note.id && title && body && helpers.hasChanged(note, { ...note, title: title, body: body })) {
          onNoteAutoSave({ ...note, title: title, body: body });
        }
      }, 10000); // Save every 10 seconds
      // clearing interval
      return () => clearInterval(timer);
    }
  }, [note, title, body]);

  const onTitleBlur = (event) => {
    const t = event.target.value;
    setTitle(t);
  };

  const handleBodyChange = (event, editorContents) => {
    setBody(editorContents);
  };

  const handleNoteSave = () => {
    onNoteSaved({ ...note, title: title, body: body });
  };

  const handleNoteCancel = () => {
    onNoteCancel();
  };

  const getModifyDate = (date) => {
    return new Date(date).toLocaleString();
  };

  // Note.Delete
  const [showNoteDeleteConfirm, setShowNoteDeleteConfirm] = useState(false);
  const handleNoteDeleteClick = () => {
    setShowNoteDeleteConfirm(true);
  };

  const handleNoteDeleteConfirm = () => {
    setShowNoteDeleteConfirm(false);
    onNoteDelete(note);
  };

  const handleNoteDeleteCancel = () => {
    setShowNoteDeleteConfirm(false);
  };

  return (
    <div className="cx-note-item-edit">
      {note ? (
        <>
          <div className="cx-note-item-edit-title">
            <TextareaAutosize
              ref={titleRef}
              id="title"
              name="title"
              aria-label="minimum height"
              minRows={2}
              defaultValue={title ?? ''}
              placeholder={lang.title}
              onBlur={onTitleBlur}
            />
          </div>

          <div
            className="cx-note-item-edit-editor"
            style={{ minHeight: noteContainerHeight - 200, maxHeight: noteContainerHeight - 200 }}
          >
            <div className="clearfix"></div>
            <SunEditor
              getSunEditorInstance={getSunEditorInstance}
              id="body"
              name="body"
              width="99.5%"
              setOptions={{
                height: '100%', // Ensure the editor height is set to 100% of its container

                buttonList: [
                  // default
                  [
                    'bold',
                    'underline',
                    'italic',
                    'strike',
                    'align',
                    'horizontalRule',
                    'list',
                    'outdent',
                    'indent',
                    'lineHeight',
                    'blockquote',
                    'table',
                    'link',
                    'codeView',
                  ],

                  ['font', 'fontSize', 'formatBlock'],
                  ['fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
                  [
                    '-right',
                    ':i-More Misc-default.more_vertical',
                    'undo',
                    'redo',
                    'showBlocks',
                    'image',
                    'video',
                    'preview',
                    'print',
                  ],
                  ['-right', 'fullScreen'],
                  // (min-width: 992)
                  [
                    '%992',
                    [
                      [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'align',
                        'horizontalRule',
                        'list',
                        'indent',
                        'outdent',
                        'lineHeight',
                        'table',
                        'link',
                        'image',
                        'video',
                        'codeView',
                      ],
                      ['font', 'fontSize', 'formatBlock'],
                      ['fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
                      [
                        '-right',
                        ':i-More Misc-default.more_vertical',
                        'undo',
                        'redo',
                        'showBlocks',
                        'image',
                        'video',
                        'preview',
                        'print',
                      ],
                    ],
                  ],
                  // (min-width: 767)
                  [
                    '%767',
                    [
                      [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'align',
                        'horizontalRule',
                        'list',
                        'indent',
                        'outdent',
                        'lineHeight',
                        'blockquote',
                        'table',
                        'link',
                        'image',
                        'codeView',
                      ],
                      ['font', 'fontSize', 'formatBlock'],
                      ['fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
                      [
                        '-right',
                        ':i-More Misc-default.more_vertical',
                        'undo',
                        'redo',
                        'showBlocks',
                        'image',
                        'video',
                        'preview',
                        'print',
                      ],
                    ],
                  ],
                  // (min-width: 480)
                  [
                    '%480',
                    [
                      [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'align',
                        'horizontalRule',
                        'list',
                        'indent',
                        'outdent',
                        'lineHeight',
                        'blockquote',
                        'table',
                        'link',
                        'image',
                      ],
                    ],
                  ],
                ],
              }}
              onBlur={handleBodyChange}
              placeholder={lang.whatIsOnYourMind}
            />
          </div>
          <div className="cx-admin-footer-actions">
            {note.id !== '' && note.accessLevel === 'Owner' ? (
              <button type="button" className="cx-button cx-button-delete" onClick={handleNoteDeleteClick}>
                {lang.delete}
              </button>
            ) : null}
            <button type="button" className="cx-button cx-button-cancel" onClick={handleNoteCancel}>
              {lang.cancel}
            </button>

            {note.id === '' ||
            (note.id !== '' && note.accessLevel === 'Owner') ||
            note.accessLevel === 'Edit' ||
            note.accessLevel === 'EditAndShare' ? (
              <button type="button" className="cx-button cx-button-save" onClick={handleNoteSave}>
                {lang.save}
              </button>
            ) : null}

            {note.id !== '' ? (
              <div className="cx-note-item-edit-updated">
                {lang.lastUpdated} {note.lastUpdatedUserName} {getModifyDate(note.modifyDate)} {note.isOwner}
              </div>
            ) : null}
          </div>

          <CxConfirm
            lang={lang}
            type="delete"
            message={`${lang.deleteConfirmationMessage}"${note.title}"?`}
            show={showNoteDeleteConfirm}
            confirmButtonText={lang.delete}
            onConfirmClick={handleNoteDeleteConfirm}
            onCancelClick={handleNoteDeleteCancel}
          />
        </>
      ) : null}
    </div>
  );
};

export default NoteEdit;
