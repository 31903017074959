import { combineReducers } from 'redux';

import { app } from '../../components/redux/app/app.reducer';
import { accountApps } from '../../components/redux/accountApps/accountApps.reducer';
import { notes } from '../../components/redux/notes/notes.reducer';
import { responsive } from '../../components/redux/responsive/responsive.reducer';
import auth from '../../components/features/account/redux/reducer';
import { search } from '../../components/features/listings/redux/search.reducer';

export default combineReducers({
  app,
  auth,
  search,
  accountApps,
  notes,
  responsive,
});
