import { CHANGELANGUAGE, SETWORKSPACE, SETCONTENTGROUP } from './app.actions';

// Localization
import LocalizedStrings from 'react-localization';
import { data } from '../../../assets/localization/localization.data';

const getCurrentCulture = () => {
  if (localStorage.getItem('culture') === null) {
    localStorage.setItem('culture', 'es-PR');
    return 'es-PR';
  }
  return localStorage.getItem('culture');
};

const getLang = (culture) => {
  const lang = new LocalizedStrings(data);
  const newCulture = culture ? culture : 'es-PR';
  lang.setLanguage(newCulture);
  localStorage.setItem('culture', newCulture);
  return lang;
};

const getInitWorkspace = () => {
  if (localStorage.getItem(SETWORKSPACE) === null) {
    return null;
  }
  return JSON.parse(localStorage.getItem(SETWORKSPACE));
};

const getInitContentGroup = () => {
  if (localStorage.getItem(SETCONTENTGROUP) === null) {
    return null;
  }
  return JSON.parse(localStorage.getItem(SETCONTENTGROUP));
};

const initialState = {
  lang: getLang(getCurrentCulture()),
  cultureCode: getCurrentCulture(),
  workspace: getInitWorkspace(),
  contentGroup: getInitContentGroup(),
};

export const app = (state = initialState, action) => {
  // console.log('reducer', state, action);

  switch (action.type) {
    case CHANGELANGUAGE:
      return {
        ...state,
        lang: getLang(action.data),
      };
    case SETWORKSPACE:
      localStorage.setItem(SETWORKSPACE, JSON.stringify(action.data));
      return {
        ...state,
        workspace: action.data,
      };
    case SETCONTENTGROUP:
      localStorage.setItem(SETCONTENTGROUP, JSON.stringify(action.data));
      return {
        ...state,
        contentGroup: action.data,
      };
    default:
      return state;
  }
};
